import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlFormatter {
  formatStringValueToUrlPath(stringValue: string | null | undefined): string {
    return stringValue ? stringValue.replaceAll(' ', '-') : '';
  }

  /*
   * Currently hyphens (-) should be used instead of underscores (_) in Kontent AI, however urlPath.replaceAll('_', ' ') is there for backward compatibility for now
   */
  formatUrlPathToStringValue(urlPath: string | null | undefined): string {
    return urlPath ? decodeURIComponent(urlPath.replaceAll('_', ' ').replaceAll('-', ' ')) : '';
  }
}
