/* eslint-disable @typescript-eslint/naming-convention */

export enum ClientSiteSettingsKeys {
  KenticoCodeNamesMainNavigation = 'Kentico.CodeNames.MainNavigation',
  KenticoCodeNamesSubNavigation = 'Kentico.CodeNames.SubNavigation',
  KenticoCodeNamesFooterMainMenu = 'Kentico.CodeNames.FooterMain',
  KenticoCodeNamesFooterMainMenu2 = 'Kentico.CodeNames.FooterMain2',
  KenticoCodeNamesFooterGlobalMenu = 'Kentico.CodeNames.FooterGlobal',
  KenticoCodeNamesFooterSocialMenu = 'Kentico.CodeNames.FooterSocial',
  KenticoCodeNamesFooterLegalMenu = 'Kentico.CodeNames.FooterLegal',
  KenticoLocalizationsGlobalLanguages = 'Kentico.Localizations.GlobalLanguages',

  GTMId = 'GTM.Id',
  DidomiConsentNoticeId = 'Didomi.ConsentNoticeId',
  TwitterCard = 'TwitterCard.Site',

  FeatureBreadcrumbsHome = 'Feature.Breadcrumbs.Home',
  FeatureBreadcrumbsIsActive = 'Feature.Breadcrumbs.IsActive',
  MultiLanguagesIsActive = 'MultiLanguages.IsActive',

  AlgoliaIsActive = 'Algolia.IsActive',
  AlgoliaApplicationId = 'Algolia.ApplicationId',
  AlgoliaSearchAPIKey = 'Algolia.SearchAPIKey',
  AlgoliaIndexName = 'Algolia.IndexName',
  LeadGenIndexingEnabled = 'Algolia.LeadGenIndexingEnabled',
  ProductIndexingEnabled = 'Algolia.ProductIndexingEnabled',
  ColorIndexingEnabled = 'Algolia.ColorIndexingEnabled',
  AlgoliaUseOwnAlgoliaCatalog = 'Algolia.UseOwnAlgoliaCatalog',
  AlgoliaProductIndexingHierarchyLevel = 'Algolia.ProductIndexing.HierarchyLevel',
  AlgoliaProductIndexingProductCategoryNodeName = 'Algolia.ProductIndexing.ProductCategoryNodeName',

  StoreLocatorKontentAICodeNameMainTitle = 'StoreLocator.KontentAI.CodeName.MainTitle',
  StoreLocatorKontentAICodeNameStorePage = 'StoreLocator.KontentAI.CodeName.StorePage',
  StoreLocatorView = 'StoreLocatorView',
  StoreLocatorDefaultMapFocus = 'StoreLocator.DefaultMapFocus',
  StoreLocatorDefaultZoomLevel = 'StoreLocator.DefaultZoomLevel',
  StoreLocatorCountryCode = 'StoreLocator.CountryCode',
  StoreLocatorBrandFilter = 'StoreLocator.BrandFilter',
  StoreLocatorKontentAICodeNameDisclaimerWarning = 'StoreLocator.KontentAI.CodeName.DisclaimerWarning',
  StoreLocatorKontentAICodeNameDisclaimerTitle = 'StoreLocator.KontentAI.CodeName.DisclaimerTitle',
  StoreLocatorKontentAICodeNameDisclaimerViewButton = 'StoreLocator.KontentAI.CodeName.DisclaimerViewButton',
  StoreLocatorKontentAICodeNameDisclaimerList1Title = 'StoreLocator.KontentAI.CodeName.DisclaimerList1Title',
  StoreLocatorKontentAICodeNameDisclaimerList1 = 'StoreLocator.KontentAI.CodeName.DisclaimerList1',
  StoreLocatorKontentAICodeNameDisclaimerList2Title = 'StoreLocator.KontentAI.CodeName.DisclaimerList2Title',
  StoreLocatorKontentAICodeNameDisclaimerList2 = 'StoreLocator.KontentAI.CodeName.DisclaimerList2',

  EcommerceIsEnabled = 'eCommerce.IsEnabled',
  EcommerceIsAllowLogin = 'eCommerce.IsAllowLogin',
  EcommerceUserProfileHideSecondaryEmail = 'eCommerce.UserProfile.HideSecondaryEmail',

  FavoritesIsEnabled = 'Favorites.IsEnabled',

  FederatedSearchIsEnabled = 'FederatedSearch.IsEnabled',

  BrandingAssetsHeaderLogo = 'Branding.Assets.HeaderLogo',
  BrandingAssetsFooterLogo = 'Branding.Assets.FooterLogo',
  BrandingAssetsFavicon = 'Branding.Assets.Favicon',
  BrandingAssetsTabText = 'Branding.Assets.TabText',
  BrandingAssetsVideoThumbnail = 'Branding.Assets.VideoThumbnail',

  EasyredirRedirectAliasDomain = 'Easyredir.RedirectAliasDomain',

  ColorJourneyIsEnabled = 'ColorJourney.IsEnabled',
  ColorJourneyLightThreshold = 'ColorJourney.LightThreshold',
  ColorJourneyDarkThreshold = 'ColorJourney.DarkThreshold',
  ColorJourneyFamilyGroupingEnabled = 'ColorJourney.FamilyGrouping.Enabled',
  ColorJourneyColorDataSectionEnabled = 'ColorJourney.ColorDataSection.Enabled',
  ColorJourneyColorDataRGBEnabled = 'ColorJourney.ColorDataRGB.Enabled',
  ColorJourneyColorDataLRVEnabled = 'ColorJourney.ColorDataLRV.Enabled',
  ColorJourneyColorDataNCSEnabled = 'ColorJourney.ColorDataNCS.Enabled',
  ColorJourneyColorCodeHidden = 'ColorJourney.ColorCode.Hidden',
  ColorJourneyFamilyTypeNames = 'ColorJourney.FamilyTypeNames',
  ColorJourneyFamilyTypeLabelCodeNames = 'ColorJourney.FamilyTypeLabelCodeNames',
  ColorJourneyFamilySwitcherButtonsPerRow = 'ColorJourney.FamilySwitcher.ButtonsPerRow',
  ColorJourneyCollectionPaginatorHidden = 'ColorJourney.CollectionPaginator.Hidden',
  ColorJourneyFamiliesPaginatorHidden = 'ColorJourney.FamiliesPaginator.Hidden',
  ColorJourneyTintsAndShadesEnabled = 'ColorJourney.TintsAndShades.Enabled',
  ColorJourneyCoordinatingColorsEnabled = 'ColorJourney.CoordinatingColors.Enabled',
  ColorJourneyCoordinatingColorsAlgorithm1 = 'ColorJourney.CoordinatingColors.Algorithm1',
  ColorJourneyCoordinatingColorsAlgorithm2 = 'ColorJourney.CoordinatingColors.Algorithm2',
  ColorJourneyCoordinatingColorsAlgorithm3 = 'ColorJourney.CoordinatingColors.Algorithm3',

  ProductJourneyProductListEnabled = 'ProductJourney.ProductList.Enabled',
  ProductJourneyProductListDataSheetEnabled = 'ProductJourney.ProductList.DataSheetEnabled',
  ProductJourneyProductDetailsIsEnabled = 'ProductJourney.ProductDetails.Enabled',
  ProductJourneyProductDetailsTransactable = 'ProductJourney.ProductDetails.Transactable',

  ECommerceJobsHideProducts = 'eCommerce.Jobs.HideProducts',

  AssetsImageUnavailable = 'Assets.ImageUnavailable',
  AssetsColorUnavailable = 'Assets.ColorUnavailable',
  AssetsColorsUnavailable = 'Assets.ColorsUnavailable',
  AssetsGlobalColorsUnavailable = 'Assets.GlobalColorsUnavailable',
  AssetsSustainabilityIcons = 'Assets.SustainabilityIcons',
}
