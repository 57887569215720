import { inject, Injectable, signal, untracked } from '@angular/core';
import { CookieOptions } from 'express-serve-static-core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

import { UrlService } from '@ppg/configuration';
import { DEFAULT_COOKIES_OPTIONS, LANGUAGE_COOKIES_KEY, SERVER_RESPONSE } from '@ppg/core/constants';
import { LoggerService } from '@ppg/core/logger';

import { LanguageUtilsService } from '../language-utils.service';

@Injectable({ providedIn: 'root' })
export class CookieLanguageService {
  readonly #logger = inject(LoggerService);
  readonly #languageUtilsService = inject(LanguageUtilsService);
  readonly #cookieService = inject(SsrCookieService);
  readonly #urlService = inject(UrlService);
  readonly #serverResponse = inject(SERVER_RESPONSE, { optional: true });
  readonly #cookieLanguage = signal<string | null>(null);

  readonly language = this.#cookieLanguage.asReadonly();

  constructor() {
    this.#init();
  }

  #init(): void {
    const initRawValue = this.#cookieService.get(LANGUAGE_COOKIES_KEY);
    const initialValue =
      initRawValue && this.#languageUtilsService.isLanguageSupported(initRawValue)
        ? this.#urlService.formatLanguageToCode(initRawValue)!
        : null;

    if (!initialValue) {
      this.#cookieService.delete(LANGUAGE_COOKIES_KEY);
    }

    this.#cookieLanguage.set(initialValue);

    this.#logger.info(
      `[CookieLanguageService] Language cookie "${LANGUAGE_COOKIES_KEY}" initialized with value: ${initialValue}`,
    );
  }

  setLanguage(language: string): void {
    this.#logger.info(`[CookieLanguageService][setLanguage] language: ${language}`);
    const oldValue = untracked(() => this.#cookieLanguage());

    this.#cookieLanguage.set(language);
    this.#updateCookieValue(language, oldValue);
  }

  #updateCookieValue(cookieValue: string, oldValue: string | null): void {
    this.#logger.info(
      `[CookieLanguageService] Language cookie "${LANGUAGE_COOKIES_KEY}" set with value: ${cookieValue}`,
    );

    if (cookieValue !== oldValue) {
      this.#cookieService.set(LANGUAGE_COOKIES_KEY, cookieValue, DEFAULT_COOKIES_OPTIONS);

      if (!this.#serverResponse?.writableEnded) {
        this.#serverResponse?.cookie?.(LANGUAGE_COOKIES_KEY, cookieValue, {
          ...DEFAULT_COOKIES_OPTIONS,
          expires: new Date(new Date().getTime() + DEFAULT_COOKIES_OPTIONS.expires * 1000 * 60 * 60 * 24),
          sameSite: (DEFAULT_COOKIES_OPTIONS.sameSite as string).toLowerCase() as CookieOptions['sameSite'],
        });
      }
    }
  }
}
