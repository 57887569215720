import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';

import { EXPERIENCE_API_REST_URL } from '@ppg/core/constants';
import { UserInfo } from '@ppg/core/models';

import { GetUserLanguageGQLResponse, UpdateUserLanguageGQLResponse } from '../../models/user-language-response.model';

@Injectable()
export class UserApiService {
  #http: HttpClient = inject(HttpClient);
  #baseUrl = inject(EXPERIENCE_API_REST_URL);
  #apollo: Apollo = inject(Apollo);

  getUserInfo() {
    return this.#http.get<UserInfo>(`${this.#baseUrl}/Users/user-info`);
  }

  getUserLanguage(email: string) {
    const query = gql`
      query getPreferences($userEmail: EmailAddress!) {
        userPreferences(userEmail: $userEmail) {
          language
        }
      }
    `;
    const variables = { userEmail: email };

    return this.#apollo
      .query<GetUserLanguageGQLResponse>({ query, variables })
      .pipe(map((response) => response.data?.userPreferences.language));
  }

  updateUserLanguage(language: string, email: string) {
    const mutation = gql`
      mutation changeLanguage($userEmail: EmailAddress!, $language: String!) {
        setUserPreferences(userPreferences: { language: $language }, userEmail: $userEmail) {
          language
        }
      }
    `;
    const variables = {
      language: language,
      userEmail: email,
    };

    return this.#apollo
      .mutate<UpdateUserLanguageGQLResponse>({ mutation, variables })
      .pipe(map((response) => response.data?.setUserPreferences.language));
  }
}
