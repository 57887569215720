import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { API_BASE_URL } from '@ppg/core/constants';

import { AuthApiServiceAbstract } from '../../../domain/repository/auth-api.service.abstract';
import { RefreshTokenResponse } from '../../models/refresh-token-response.model';

@Injectable()
export class AuthApiService implements AuthApiServiceAbstract {
  #http = inject(HttpClient);
  #baseUrl = inject(API_BASE_URL);

  refreshToken(): Observable<boolean> {
    return this.#http
      .get<RefreshTokenResponse>(`${this.#baseUrl}auth/refresh-token`)
      .pipe(map(({ success }) => success));
  }
}
