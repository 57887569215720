export function stringKeyOfEnum<T extends object>(enumObj: T, stringValue: string): string | undefined {
  const reverseMap = new Map<string, T[keyof T]>();
  const enumKeys: T[keyof T][] = Object.keys(enumObj).map((key) => key as unknown as T[keyof T]);
  const enumStrValues: string[] = Object.values(enumObj);

  for (let i = 0; i < enumKeys.length; i++) {
    reverseMap.set(enumStrValues[i], enumKeys[i]);
  }

  return reverseMap.get(stringValue) as string;
}
