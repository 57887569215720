export const USER_BROWSER_LANGUAGE_KEY = 'userBrowserLanguage';
export const CONFIGURATION_KEY = 'configuration';
export const ENVIRONMENT_VARIABLES_KEY = 'environmentVariables';
export const INITIAL_LANGUAGE_STATE_KEY = 'initialLanguageState';
export const HEADER_INITIAL_STATE_KEY = 'headerInitialState';
export const ALGOLIA_CONFIGURATION_KEY = 'algoliaConfiguration';
export const ALGOLIA_SECURED_KEY = 'cdeSecuredKey';
export const ALGOLIA_APPLICATION_ID = 'cdeApplicationId';
export const ALGOLIA_CONTENT_INDEX = 'contentIndex';
export const ALGOLIA_PRODUCT_INDEX = 'productIndex';
export const ALGOLIA_COLOR_INDEX = 'colorIndex';
export const IS_AUTHENTICATED_KEY = 'isAuthenticated';
export const ALGOLIA_USER_COOKIE = 'cdeUserCookie';
export const GLOBAL_STATIC_ASSETS_KEY = 'globalStaticAssets';
