import { inject, Injectable } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';

@Injectable({ providedIn: 'root' })
export class SiteConfigLanguageService {
  private readonly currentSiteState = inject(CurrentSiteState);

  readonly language = this.currentSiteState.defaultLanguage;
}
