export const PAGE_LEVEL_META = {
  name: {
    description: 'description',
    robots: 'robots',
    keywords: 'keywords',
    twitter_card: 'twitter:card',
    twitter_site: 'twitter:site',
    twitter_description: 'twitter:description',
    twitter_image: 'twitter:image',
  } as const,
  property: {
    og_url: 'og:url',
    og_type: 'og:type',
    og_title: 'og:title',
    og_description: 'og:description',
    og_image: 'og:image',
    twitter_title: 'twitter:title',
    og_image_width: 'og:image:width',
    og_image_height: 'og:image:height',
  } as const,
  content: {
    indexSeoFalse: 'noindex, nofollow',
    twitter_card: 'summary_large_image',
    og_image_width: '400',
    og_image_height: '300',
  } as const,
  rel: {
    canonical: 'canonical',
  } as const,
} as const;
