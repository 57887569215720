import { MonoTypeOperatorFunction, Observable, Subscriber } from 'rxjs';

/**
 * Executes provided callback when source observable is cancelled.
 *
 * `onCancel` vs `finalize`:
 * - `finalize`: Executes a callback on completion, error, or unsubscription.
 *   It does not differentiate between these scenarios.
 * - `onCancel`: Specifically targets cancellations - unsubscriptions before completion or error.
 *   It does not execute the callback on natural completion or error.
 *
 * Example:
 * ```ts
 * const source$ = interval(1000);
 * const subscription = source$.pipe(
 *   take(5),
 *   finalize(() => console.log('Finalized')),
 *   onCancel(() => console.log('Cancelled'))
 * ).subscribe(
 *   value => console.log(value),
 *   err => console.error(err),
 *   () => console.log('Completed')
 * );
 *
 * setTimeout(() => {
 *   subscription.unsubscribe();
 * }, 2500);
 *
 * // Output:
 * // 0, 1, Finalized, Cancelled
 *
 * // If timeout=6000 (natural completion), output will be:
 * // 0, 1, 2, 3, 4, Completed, Finalized
 * ```
 */
export function onCancel<T>(callback: () => void): MonoTypeOperatorFunction<T> {
  return function (source: Observable<T>): Observable<T> {
    return new Observable<T>((subscriber: Subscriber<T>) => {
      let completed = false;
      let errored = false;

      const subscription = source.subscribe({
        next(value: T): void {
          subscriber.next(value);
        },
        error(err: unknown): void {
          errored = true;
          subscriber.error(err);
        },
        complete(): void {
          completed = true;
          subscriber.complete();
        },
      });

      return () => {
        subscription.unsubscribe();

        if (!completed && !errored) {
          callback();
        }
      };
    });
  };
}
