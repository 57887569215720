import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { CONTENT_PREVIEW_MODE, LANGUAGE_COUNTRY_CODE } from '@ppg/core/constants';

import { ConfigManagerService } from '../config-manager/config-manager.service';

@Injectable({ providedIn: 'root' })
export class UrlService {
  readonly #configManagerService = inject(ConfigManagerService);
  readonly #location = inject(Location);
  readonly #dummyBaseUrl = 'https://example.com';
  readonly #globalLanguageRegex = /^[a-z]{2}-global$/i;

  isLanguage(lang: string): boolean {
    if (this.#isPreviewMode()) {
      return this.#globalLanguageRegex.test(lang) || LANGUAGE_COUNTRY_CODE.test(lang);
    }

    return LANGUAGE_COUNTRY_CODE.test(lang);
  }

  formatLanguageToCode(lang: string): string | undefined {
    if (!this.isLanguage(lang)) {
      return;
    }

    const [firstLangPart, secondLangPart] = lang.split('-');

    if (this.#isPreviewMode() && this.#globalLanguageRegex.test(lang)) {
      return `${firstLangPart}-${secondLangPart}`;
    }

    return `${firstLangPart}-${secondLangPart.toUpperCase()}`;
  }

  getChunks(url: string): string[] {
    const { pathname } = new URL(url, this.#dummyBaseUrl);

    return pathname.split('/').filter(Boolean);
  }

  getSlug(url: string): string | undefined {
    const chunks = this.getChunks(url);
    const rootNameIndex = chunks.findIndex((chunk) => this.#configManagerService.isActiveSiteRootName(chunk));
    const hierarchyAndSlugChunks = chunks.slice(rootNameIndex + 1);

    const slug = hierarchyAndSlugChunks.pop();

    if (slug && this.isLanguage(slug)) {
      return undefined;
    }

    return slug?.toLowerCase();
  }

  getLanguage(url: string): string | undefined {
    const chunks = this.getChunks(url);

    if (this.#isPreviewMode()) {
      for (const chunk of chunks) {
        if (this.isLanguage(chunk)) {
          return chunk;
        }
      }

      return undefined;
    }

    const languageSegment = chunks[0];

    return this.isLanguage(languageSegment) ? languageSegment : undefined;
  }

  getCollectionName(url: string): string | undefined {
    const chunks = this.getChunks(url);
    const filteredChunks = chunks.filter((chunk) => chunk !== CONTENT_PREVIEW_MODE && !this.isLanguage(chunk));

    return filteredChunks[0]?.toLowerCase();
  }

  getCodename(url: string): string | undefined {
    if (!url) {
      return undefined;
    }

    const chunks = this.getChunks(url);
    const filteredChunks = chunks.filter((chunk) => chunk !== CONTENT_PREVIEW_MODE && !this.isLanguage(chunk)).slice(1);
    return filteredChunks[filteredChunks.length - 1];
  }

  getContentPreviewModeIndex(url: string): number {
    const chunks = this.getChunks(url);
    return chunks.findIndex((chunk) => chunk === CONTENT_PREVIEW_MODE);
  }

  isAbsoluteUrl(url: string): boolean {
    return url.startsWith('http://') || url.startsWith('https://');
  }

  getQueryStringAndHash(url: string): string {
    const { search, hash } = new URL(url, this.#dummyBaseUrl);

    return `${search}${hash}`;
  }

  #isPreviewMode(): boolean {
    const path = this.#location.path(); // Note: isPreview will be true when redirecting the URL from preview to non-preview

    const previewModeIndex = this.getContentPreviewModeIndex(path);

    return previewModeIndex !== -1;
  }
}
