import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { inject, Injectable, Injector, makeStateKey, PLATFORM_ID, StateKey, TransferState } from '@angular/core';

import { LoggerService } from '@ppg/core/logger';

import { APP_CACHE_PROVIDER } from './server';

@Injectable({
  providedIn: 'root',
})
export class CacheProviderService {
  private readonly injector: Injector = inject(Injector);
  private readonly transferState: TransferState = inject(TransferState);
  private readonly loggerService: LoggerService = inject(LoggerService);
  private readonly cacheProvider = inject(APP_CACHE_PROVIDER, { optional: true });

  getValue<T>(key: string): T | null {
    const stateKey = this.getStateKey<T>(key);

    if (isPlatformBrowser(this.injector.get(PLATFORM_ID)) && this.transferState.hasKey(stateKey)) {
      return this.transferState.get<T | null>(stateKey, null);
    }

    if (isPlatformServer(this.injector.get(PLATFORM_ID)) && this.cacheProvider?.hasValue(key)) {
      this.loggerService.info(`[CacheProviderService][getValue] isPlatformServer for CacheProvider with key: ${key}`);
      const cachedValue = this.cacheProvider.getValue<T>(key);
      this.transferState.set(stateKey, cachedValue);
      return cachedValue;
    }

    return null;
  }

  setValue<T>(key: string, value: T): void {
    const stateKey = this.getStateKey<T>(key);

    if (this.cacheProvider !== null && !this.cacheProvider?.hasValue(key)) {
      this.loggerService.info(`[CacheProviderService][setValue] for CacheProvider with key: ${key}`);
      this.cacheProvider?.setValue(key, value);
    }

    if (!this.transferState.hasKey(stateKey)) {
      this.loggerService.info(`[CacheProviderService][setValue] for TransferState with stateKey: ${stateKey}`);
      this.transferState.set(stateKey, value);
    }
  }

  private getStateKey<T>(key: string): StateKey<T> {
    return makeStateKey<T>(key);
  }
}
