import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { staticContentFactory } from '../../lib/di/static-content.factory';
import { StaticContentService } from '../../lib/static-content-service/static-content-service';

export function provideStaticContent(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: staticContentFactory,
      deps: [StaticContentService],
      multi: true,
    },
  ]);
}
