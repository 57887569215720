import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable, makeStateKey, PLATFORM_ID, signal, TransferState } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { catchError, map, Observable, of } from 'rxjs';

import { USE_IN_MEMORY_CACHE } from '@ppg/core/cache-memory';
import {
  OCP_APIM_SUBSCRIPTION_KEY_HEADER,
  GLOBAL_STATIC_ASSETS_KEY,
  GLOBAL_STATIC_ASSETS_TOKEN,
} from '@ppg/core/constants';
import { EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';
import { LoggerService } from '@ppg/core/logger';
import { StaticAssets } from '@ppg/core/models';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  readonly #loggerService: LoggerService = inject(LoggerService);
  readonly #transferState = inject(TransferState);
  readonly #platformId = inject(PLATFORM_ID);
  readonly #environment = inject(EnvironmentService);
  readonly #http = inject(HttpClient);

  readonly #globalStaticAssetsData = inject(GLOBAL_STATIC_ASSETS_TOKEN, { optional: true });
  readonly #globalStaticAssetsKey = makeStateKey<StaticAssets>(GLOBAL_STATIC_ASSETS_KEY);
  readonly #globalStaticAssets = signal<StaticAssets | null>(null);

  constructor() {
    this.#setupStaticSitesConfig();
  }

  getStaticSiteConfig(siteId: number, language?: string): Observable<StaticAssets | null> {
    return this.getSiteStaticAssets(siteId, language);
  }

  getGlobalAssetsConfig(): Observable<StaticAssets | null> {
    return toObservable(this.#globalStaticAssets);
  }

  #setupStaticSitesConfig() {
    if (isPlatformServer(this.#platformId) && this.#globalStaticAssetsData) {
      this.#loggerService.info('[ConfigService] Global static assets are set in the transfer state on the server');
      this.#globalStaticAssets.set(this.#globalStaticAssetsData);
      this.#transferState.set<StaticAssets | null>(this.#globalStaticAssetsKey, this.#globalStaticAssetsData);
    } else if (isPlatformBrowser(this.#platformId)) {
      this.#globalStaticAssets.set(this.#transferState.get<StaticAssets | null>(this.#globalStaticAssetsKey, null));
    }
  }

  getSiteStaticAssets(siteId?: number, language?: string | null): Observable<StaticAssets | null> {
    const contentApiUrl = `${this.#environment.getEnvironmentVariable(EnvVarsNames.APIM_CONTENT_API_BASE_URL)}v2/`; // TBD remove in scope of US 986553
    const subscriptionKey = this.#environment.getEnvironmentVariable(EnvVarsNames.APIM_CONTENT_API_SUBSCRIPTION_KEY);
    const headers = new HttpHeaders().set(OCP_APIM_SUBSCRIPTION_KEY_HEADER, subscriptionKey ?? '');
    const params = this.#getHttpParams({
      ...(siteId && { siteId }),
      ...(language && { lang: language }),
    });

    const getSiteAssetsUrlWithParams = `${contentApiUrl}sites/staticassets?${params.toString()}`;
    const cacheContext = new HttpContext().set(USE_IN_MEMORY_CACHE, getSiteAssetsUrlWithParams);

    this.#loggerService.info(`[ConfigService] Fetching ${siteId} site static assets`);

    return this.#http.get<StaticAssets | null>(getSiteAssetsUrlWithParams, { context: cacheContext, headers }).pipe(
      map((assets: StaticAssets | null) => {
        return assets ?? { items: [] };
      }),
      catchError((e: HttpErrorResponse) => {
        this.#loggerService.error(
          `[ConfigService][getSiteStaticAssets] Error occurred while fetching static assets for site ${siteId}: ${e.message}.`,
        );
        return of(null);
      }),
    );
  }

  #getHttpParams(paramsObj: { [param: string]: string | number }): HttpParams {
    return new HttpParams({ fromObject: paramsObj });
  }
}
