import { computed, inject, Injectable, makeStateKey, signal, TransferState } from '@angular/core';

import { EnvTransferConfig } from './env-config.model';
import { ENV_TRANSFER_TOKEN } from './env-transfer.token';

@Injectable()
export class EnvTransferService {
  #env = inject(ENV_TRANSFER_TOKEN, { optional: true });
  #transferState = inject(TransferState);
  #config = signal<EnvTransferConfig | null>(null);

  apim = computed(() => this.#config()?.apim ?? null);
  rest = computed(() => this.apim()?.rest ?? null);

  static readonly envTransferKey = makeStateKey<EnvTransferConfig>('EnvTransferConfig');

  constructor() {
    this.initTransport();
  }

  /**
   * ENV_TRANSFER_TOKEN will be provided only in the server context so no check should be required
   */
  initTransport() {
    let config: EnvTransferConfig | null = null;
    if (this.#env) {
      config = this.#env;
      this.#transferState.set(EnvTransferService.envTransferKey, config);
    } else {
      config = this.#transferState.get(EnvTransferService.envTransferKey, null);
    }
    this.#config.set(config);

    return config ? Promise.resolve(config) : Promise.reject('No EnvTransferConfig found');
  }
}
