export enum FeatureModule {
  Authentication = 'authentication',
  // ECom
  Registration = 'registration',
  B2bB2cCustomerRegistration = 'b2b_b2c_customer_registration',
  CreateB2CCustomer = 'create_b2c_customer',
  CreateCashCustomer = 'create_cash_customer',
  ProductsList = 'products_list',
  Favorites = 'favorites',
  ImportAccounts = 'import_accounts',
  Admin = 'admin',
  Users = 'users',
  Job = 'job',
  Customer = 'customer',
  Order = 'order',
  PaymentMethods = 'payment_methods',
  UserProfile = 'user_profile',
  Cart = 'cart',
  Address = 'address',
  InactiveUser = 'inactive_user',

  RedirectToProducts = 'redirect_to_products',

  // Product Journey
  ProductJourneyList = 'product_journey_list',
  ProductJourneyDetails = 'product_journey_details',

  // Color
  ColorFamilies = 'color_families',
  ColorDetails = 'color_details',
  FavoriteColors = 'favorite_colors',
  ColorCollection = 'color_collection',
  Colors = 'colors',

  // Content
  DistributorLocator = 'distributor_locator',
  GlobalSearch = 'global_search',
  ContactUsForm = 'contact_us_form',
}
