import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { inject, Injectable, makeStateKey, PLATFORM_ID, signal, TransferState } from '@angular/core';

import { API_BASE_URL, IS_AUTHENTICATED, IS_AUTHENTICATED_KEY, SERVER_RESPONSE } from '@ppg/core/constants';

import { AuthService } from '../repository/auth-service.abstract';

@Injectable()
export class AuthUseCases implements AuthService {
  readonly #platformId = inject(PLATFORM_ID);
  readonly #baseUrl = inject(API_BASE_URL);
  readonly #document = inject(DOCUMENT);
  readonly #transferState = inject(TransferState);
  readonly #isAuthenticatedStateKey = makeStateKey<boolean>(IS_AUTHENTICATED_KEY);
  readonly #isAuthenticatedState = inject(IS_AUTHENTICATED, { optional: true });
  readonly #serverResponse = inject(SERVER_RESPONSE, { optional: true });

  readonly #authenticated = signal(false);
  readonly isAuthenticated = this.#authenticated.asReadonly();

  constructor() {
    if (isPlatformServer(this.#platformId) && this.#isAuthenticatedState !== null) {
      this.#authenticated.set(this.#isAuthenticatedState);
      this.#transferState.set<boolean>(this.#isAuthenticatedStateKey, this.#isAuthenticatedState);
    } else if (isPlatformBrowser(this.#platformId)) {
      this.#authenticated.set(this.#transferState.get<boolean>(this.#isAuthenticatedStateKey, false));
    }
  }

  logIn(language: string, successRedirect: string, mode?: string): void {
    this.#redirect(
      `${this.#baseUrl}auth/login-url?lang=${language}&regMode=${mode}&successRedirect=${successRedirect}`,
    );
  }

  logOut(): void {
    this.#redirect(`${this.#baseUrl}auth/signout`);
  }

  #redirect(url: string): void {
    if (isPlatformBrowser(this.#platformId)) {
      this.#document.location.href = url;
    } else if (isPlatformServer(this.#platformId) && this.#serverResponse) {
      this.#serverResponse.redirect(HttpStatusCode.Found, url);
    }
  }
}
