import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InitialLanguageState {
  readonly #language = signal('');
  readonly language = this.#language.asReadonly();

  setLanguage(value: string): void {
    if (!value || this.#language()) {
      return;
    }

    this.#language.set(value);
  }
}
