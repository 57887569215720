import { CookieOptions } from 'express';

export const SESSION_MAX_AGE = 14 * 24 * 60 * 60 * 1000; // 14d in ms

export const SESSION_COOKIE_OPTIONS: CookieOptions = {
  httpOnly: true,
  maxAge: SESSION_MAX_AGE,
  secure: false, // set to true as soon as switch to https
};

export const STORE_LOCATOR_SEARCH_STATE = 'StoreLocatorSearchState';
