import { computed, inject, Injectable } from '@angular/core';

import { AuthService, UserService } from '@ppg/auth';
import { UrlService } from '@ppg/configuration';
import { LoggerService } from '@ppg/core/logger';

import { CookieLanguageService } from './language-providers/cookie-language.service';
import { SiteConfigLanguageService } from './language-providers/site-config-language.service';
import { UrlLanguageService } from './language-providers/url-language.service';
import { UserBrowserLanguageService } from './language-providers/user-browser-language.service';
import { LanguageUtilsService } from './language-utils.service';

export const ALL_LANGUAGE_SOURCES = ['userPreferences', 'url', 'cookie', 'userBrowser', 'defaultSite'] as const;

export type LanguageSource = (typeof ALL_LANGUAGE_SOURCES)[number];
export const LANGUAGE_SOURCE = ALL_LANGUAGE_SOURCES.reduce(
  (acc, rec) => ({ ...acc, [rec]: rec }),
  {} as { [K in LanguageSource]: K },
);

export interface LanguageResolveResult {
  language: string;
  languageSources: Record<LanguageSource, string | null>;
}

/**
 * Language resolution logic:
 * If user is authenticated, will be used Auth flow, otherwise non auth flow.
 *
 * Auth flow:
 * Use user language if not empty and supported by site, otherwise go with non auth flow
 *
 * Non auth flow:
 * Take first language that is supported by site:
 * 1. language from url
 * 2. language from cookie
 * 3. language from user browser settings - taken from Accept-Language header on server side
 * 4. default site language
 * */
@Injectable({ providedIn: 'root' })
export class LanguageResolutionService {
  readonly #logger = inject(LoggerService);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #languageUtilsService = inject(LanguageUtilsService);
  readonly #urlLanguageService = inject(UrlLanguageService);
  readonly #urlService = inject(UrlService);
  readonly #cookieLanguageService = inject(CookieLanguageService);
  readonly #userBrowserLanguageService = inject(UserBrowserLanguageService);
  readonly #siteConfigLanguageService = inject(SiteConfigLanguageService);

  readonly #fallbackResolutionOrder = computed(
    () =>
      [
        { sourceType: LANGUAGE_SOURCE.cookie, language: this.#cookieLanguageService.language() },
        { sourceType: LANGUAGE_SOURCE.userBrowser, language: this.#userBrowserLanguageService.language() },
        { sourceType: LANGUAGE_SOURCE.defaultSite, language: this.#siteConfigLanguageService.language() },
      ] satisfies { sourceType: string; language: string | null }[],
  );

  resolveLanguage(url: string): LanguageResolveResult {
    let language = '';
    let userLanguage: string | null = null;
    let urlLanguage: string | null = null;

    if (this.#authService.isAuthenticated()) {
      userLanguage = this.#userService.language();
      language = userLanguage || '';

      if (!language) {
        this.#logger.info(
          '[LanguageResolution] user has an empty user language, starting fallback language calculation',
        );
        language = '';
      }

      if (language && !this.#languageUtilsService.isLanguageSupported(language)) {
        this.#logger.info(
          '[LanguageResolution] user language does not support by site, starting fallback language calculation',
        );
        language = '';
      }
    }

    urlLanguage = this.#urlLanguageService.getRawLanguageFromUrl(url);

    language ||= this.#resolveAnonymousLanguage(urlLanguage);

    if (!language) {
      this.#logger.error('[LanguageResolution] unable to resolve the language, default site language is empty');
    }

    return {
      language,
      languageSources: this.#generateLanguageSourcesResult(userLanguage, urlLanguage),
    };
  }

  #resolveAnonymousLanguage(urlLanguage: string | null): string {
    const formattedUrlLanguage = urlLanguage && this.#urlService.formatLanguageToCode(urlLanguage);

    if (formattedUrlLanguage) {
      return formattedUrlLanguage;
    }

    const fallbackLanguages = this.#fallbackResolutionOrder();

    this.#logger.info('[LanguageResolution] cannot resolve url language, starting fallback');
    this.#logger.info(
      `[LanguageResolution] fallback languages: ${fallbackLanguages.map((source) => `${source.sourceType}: ${source.language || 'null'}`).join(', ')}`,
    );

    // We cast to existing because in the end we have default site language that should not be empty
    return fallbackLanguages.find((fallback) => fallback.language)?.language || '';
  }

  #generateLanguageSourcesResult(
    userLanguage: string | null,
    urlLanguage: string | null,
  ): Record<LanguageSource, string | null> {
    const fallbackLanguages = this.#fallbackResolutionOrder();
    const baseResult = fallbackLanguages.reduce(
      (acc, rec) => ({ ...acc, [rec.sourceType]: rec.language }),
      {} as Record<(typeof fallbackLanguages)[number]['sourceType'], string | null>,
    );

    return {
      ...baseResult,
      [LANGUAGE_SOURCE.url]: urlLanguage,
      [LANGUAGE_SOURCE.userPreferences]: userLanguage,
    };
  }
}
