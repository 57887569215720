import { inject, Injectable } from '@angular/core';
import { Route, Routes } from '@angular/router';

import { AppRouteItem } from '@ppg/core/content';

import { BlankComponent } from '../components/blank/blank.component';
import { FEATURE_MODULE_ROUTE_DATA_KEY } from '../constants/feature-route-data';
import { ROUTER_CONFIGURATOR } from '../di/router-configurator';
import { dynamicRouterUpdaterGuard, dynamicRouterUpdaterRedirectGuard } from '../guards/dynamic-router-updater-guard';
import { FeatureModuleRouteData } from '../models/feature-route-data.model';

@Injectable({ providedIn: 'root' })
export class RouterConfigBuilderService {
  readonly #routerConfigurator = inject(ROUTER_CONFIGURATOR);

  readonly language = this.#routerConfigurator.language;

  getRouterConfig(items: AppRouteItem[]): Routes {
    return [
      this.#buildRouterUpdaterRoute(),
      ...items.map((item) => this.#mapAppRouteToRouteConfig(item)).flat(),
      ...this.#routerConfigurator.getStaticRoutes(),
    ];
  }

  #mapAppRouteToRouteConfig(appRouteItem: AppRouteItem): Route[] {
    const { featureModule, isAuthenticationRequired } = appRouteItem;
    return [
      ...this.#routerConfigurator.getFeatureModuleRoute(featureModule).map((routeConfig) => ({
        ...routeConfig,
        path: this.#buildRoutePath(routeConfig, appRouteItem),
        data: this.#buildRouteData(routeConfig, appRouteItem),
        canActivate: [
          ...(routeConfig.canActivate ?? []),
          ...(isAuthenticationRequired ? this.#routerConfigurator.getAuthGuards(featureModule) : []),
        ],
        canActivateChild: [
          ...(routeConfig.canActivateChild ?? []),
          ...(isAuthenticationRequired ? this.#routerConfigurator.getAuthGuards(featureModule) : []),
        ],
      })),
    ];
  }

  #buildRoutePath(routeConfig: Route, appRouteItem: AppRouteItem): string {
    let path = appRouteItem.url;
    if (path.startsWith('/')) {
      path = path.slice(1);
    }

    if (routeConfig.path) {
      path += `/${routeConfig.path}`;
    }
    return path;
  }

  #buildRouteData(routeConfig: Route, appRouteItem: AppRouteItem): Route['data'] {
    const routeData = routeConfig.data ? { ...routeConfig.data } : {};
    const featureModuleData: FeatureModuleRouteData = {
      appRouteItem,
    };

    return {
      ...routeData,
      [FEATURE_MODULE_ROUTE_DATA_KEY]: {
        ...featureModuleData,
        ...routeData[FEATURE_MODULE_ROUTE_DATA_KEY],
      },
    };
  }

  #buildRouterUpdaterRoute(): Route {
    return {
      path: '**',
      component: BlankComponent,
      canMatch: [dynamicRouterUpdaterGuard],
      canActivate: [dynamicRouterUpdaterRedirectGuard],
    };
  }
}
