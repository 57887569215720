import { inject, Injectable } from '@angular/core';
import { map, tap } from 'rxjs';

import { CurrentSiteState, ConfigService } from '@ppg/configuration';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  readonly #configService: ConfigService = inject(ConfigService);
  readonly #currentSite: CurrentSiteState = inject(CurrentSiteState);

  public getIcon(type: string, code: string, language: string) {
    return this.getIcons(type, [code], language).pipe(map((arr) => arr?.at(0)));
  }

  public getIcons(type: string, codes: string[], language: string) {
    const siteId = this.#currentSite.siteId();

    return this.#configService.getSiteStaticAssets(siteId, language).pipe(
      map((assets) => {
        return assets?.items.filter((asset) => {
          type = type.toLowerCase();

          let result = undefined;

          if (language) {
            language = language.replace('-', '_').toLowerCase();
            result = codes?.find((code) => {
              return asset.codename.endsWith(`icon_${type}__${language}__${this.normalizeCode(code)}`);
            });
          }

          if (!result) {
            result = codes?.find((code) => asset.codename.endsWith(`icon_${type}__${this.normalizeCode(code)}`));
          }

          return result !== undefined;
        });
      }),
      tap((assets) => {
        // log missing assets
        codes.forEach((code) => {
          if (
            codes?.length > 0 &&
            !assets?.find(
              (asset) =>
                asset.codename.endsWith(`icon_${type}__${language}__${this.normalizeCode(code)}`) ||
                asset.codename.endsWith(`icon_${type}__${this.normalizeCode(code)}`),
            )
          ) {
            console.log(`Asset for ${code} [${type}] ${language ? '[' + language + ']' : ''} not found`);
          }
        });
      }),
    );
  }

  private normalizeCode(code: string) {
    return code.toLowerCase().replace(/[^a-z0-9]/g, '_');
  }
}
