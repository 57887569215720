import { isPlatformBrowser } from '@angular/common';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  inject,
  Injector,
  makeEnvironmentProviders,
  PLATFORM_ID,
  runInInjectionContext,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';

import { CurrentSiteState } from '@ppg/configuration';
import { SERVER_RESPONSE } from '@ppg/core/constants';

import { LanguageHydrationService } from './init/language-hydration.service';
import { ServerLanguageResolverService } from './init/server-language-resolver.service';

export function serverLanguageResolutionFactory(): () => Observable<void> {
  const serverLanguageResolver = inject(ServerLanguageResolverService);
  return () => serverLanguageResolver.init();
}

// Disable SSR for E-commerce
// TODO: when SSR for ecom will be enabled
function disabledEComSSRWorkaround(): () => Observable<void> {
  const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  const isECommerceEnabled = inject(CurrentSiteState).isECommerceEnabled();

  if (!isBrowser || !isECommerceEnabled) {
    return () => of(undefined);
  }

  const injector = inject(Injector);
  const router = inject(Router);
  const emulatedServerInjector = Injector.create({
    parent: injector,
    providers: [
      ServerLanguageResolverService,
      {
        provide: SERVER_RESPONSE,
        useValue: {
          writableEnded: false,
          redirect: (_: unknown, url: string) => {
            router.navigateByUrl(url);
          },
        },
      },
    ],
  });

  return runInInjectionContext(emulatedServerInjector, serverLanguageResolutionFactory);
}

export function provideLanguageHydration(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const languageHydrationService = inject(LanguageHydrationService);
        // Disable SSR for E-commerce
        // TODO: when SSR for ecom will be enabled replace the code below with the following:
        // return () => languageHydrationService.init();
        const preInit = disabledEComSSRWorkaround();
        return () => preInit().pipe(tap(() => languageHydrationService.init()));
      },
      multi: true,
    },
  ]);
}
