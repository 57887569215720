import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { inject, Injectable, TransferState } from '@angular/core';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { AuthService, UserService } from '@ppg/auth';
import { SERVER_RESPONSE } from '@ppg/core/constants';
import { LoggerService } from '@ppg/core/logger';

import { initialLanguageStateKey } from './initial-language-state-key';
import { InitialLanguageState } from './initial-language-state.service';
import { CookieLanguageService } from '../language-providers/cookie-language.service';
import { UrlLanguageService } from '../language-providers/url-language.service';
import { LanguageResolutionService } from '../language-resolution.service';

@Injectable({ providedIn: 'root' })
export class ServerLanguageResolverService {
  readonly #loggerService = inject(LoggerService);
  readonly #transferState = inject(TransferState);
  readonly #initialLanguage = inject(InitialLanguageState);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #languageResolution = inject(LanguageResolutionService);
  readonly #location = inject(Location);
  readonly #urlLanguageService = inject(UrlLanguageService);
  readonly #cookieLanguageService = inject(CookieLanguageService);
  readonly #serverResponse = inject(SERVER_RESPONSE);

  /**
   * Initialize language platform
   *
   * 1. If user is authenticated:
   *    * Wait for user language preferences load
   * 2. Get resolve language result
   * 3. Set language in cookie
   * 4. If language in url and resolved language is different:
   *    * Emit 302 Found redirect
   *    * Stop initialization
   * 5. set resolved language as initial language
   */
  init(): Observable<void> {
    return of(null).pipe(
      switchMap(() => {
        if (this.#authService.isAuthenticated()) {
          this.#loggerService.info('[ServerLanguageResolver] waiting for user language to be loaded');
          return this.#waitUserLanguageLoad().pipe(
            tap(() => this.#loggerService.info('[ServerLanguageResolver] waiting finished, user language is loaded')),
          );
        }

        return of(null);
      }),
      map(() => {
        this.#initLanguage();
      }),
    );
  }

  #initLanguage(): void {
    const currentPath = this.#location.path();

    const { language, languageSources } = this.#languageResolution.resolveLanguage(currentPath);

    if (!language) {
      this.#loggerService.error('[ServerLanguageResolver] cannot initialize language');
      return;
    }

    if (languageSources.cookie !== language) {
      this.#cookieLanguageService.setLanguage(language);
    }

    if (languageSources.url !== language) {
      const redirectUrl = this.#urlLanguageService.generateUrl(currentPath, language);

      this.#loggerService.info(
        `[ServerLanguageResolver] url contains wrong language, received: "${languageSources.url}". Redirecting to "${redirectUrl}"`,
      );

      if (!this.#serverResponse.writableEnded) {
        this.#serverResponse.redirect(HttpStatusCode.MovedPermanently, redirectUrl);
      }
    }

    this.#loggerService.info(`[ServerLanguageResolver] initialized with ${language}`);

    this.#initialLanguage.setLanguage(language);
    this.#transferState.set(initialLanguageStateKey, language);
  }

  #waitUserLanguageLoad(): Observable<void> {
    return this.#userService.loadingState$.pipe(
      map((state) => {
        const done = state.languageLoaded;
        this.#loggerService.info(
          `[ServerLanguageResolver][waitUserLanguageLoad] state:${JSON.stringify(state)}, done: ${done}`,
        );
        return done;
      }),
      filter((done) => done),
      take(1),
      map(() => {}),
    );
  }
}
