import { Injectable, NgZone, inject } from '@angular/core';
import type { DependencyTelemetry } from 'applicationinsights/out/src/declarations/contracts/telemetryTypes/dependencyTelemetry';
import type { BaseLogger } from 'pino';

import { LOGGER } from '@ppg/core/constants';

import { fallbackLogger } from './extra-loggers/fallback-logger/fallback-logger';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  readonly #logger: BaseLogger | null = inject<BaseLogger | null>(LOGGER, { optional: true }) ?? fallbackLogger;
  readonly #ngZone: NgZone = inject(NgZone);

  #runOutsideAngular<T>(loggerFunction: ((messageObject: T) => void) | undefined, messageObject: T): void {
    if (loggerFunction) {
      this.#ngZone.runOutsideAngular(() => loggerFunction(messageObject));
    }
  }

  public trace(message: string): void {
    this.#runOutsideAngular(this.#logger?.trace.bind(this.#logger), message);
  }

  public debug(message: string): void {
    this.#runOutsideAngular(this.#logger?.debug.bind(this.#logger), message);
  }

  public info(message: string): void {
    this.#runOutsideAngular(this.#logger?.info.bind(this.#logger), message);
  }

  public warn(message: string): void {
    this.#runOutsideAngular(this.#logger?.warn.bind(this.#logger), message);
  }

  public error(message: string): void {
    this.#runOutsideAngular(this.#logger?.error.bind(this.#logger), message);
  }

  public fatal(message: string): void {
    this.#runOutsideAngular(this.#logger?.fatal.bind(this.#logger), message);
  }

  public trackDependency(object: DependencyTelemetry): void {
    this.#runOutsideAngular<DependencyTelemetry>(this.#logger?.info.bind(this.#logger), object);
  }
}
