export enum ContainerContentBannerPresentation {
  LARGE = 'large',
  SMALL = 'small',
  HORIZONTAL_TALL = 'horizontal_tall',
  HORIZONTAL_STANDARD = 'horizontal_standard',
  HORIZONTAL_STANDARD_50 = 'horizontal_standard_50',
  HORIZONTAL_SHORT = 'horizontal_short',
  CAROUSEL = 'carousel',
}

export enum ContainerContentBannerTheme {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
  LIGHT = 'light',
  DARK = 'dark',
}

export enum BackgroundClass {
  DARK = 'dark-theme',
  LIGHT = 'light-theme',
  TRANSPARENT = 'transparent-theme',
  DEFAULT = 'default-theme',
}
