import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { FeatureModule } from '@ppg/core/content';
import { AuthStaticRoutes } from '@ppg/core/enums';
import { AppRoutesService } from '@ppg/core/navigation';

import { AuthService } from '../../domain/repository/auth-service.abstract';

/**
 * Guard checks if user is authenticated if not redirects to login page
 */
export const isAuthenticatedGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const appRoutesService = inject(AppRoutesService);

  const authUrl = appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.Authentication);

  return authService.isAuthenticated() || (authUrl ? router.createUrlTree([authUrl, AuthStaticRoutes.LOGIN]) : false);
};
