import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';

import { LoggerService } from '@ppg/core/logger';

import { initialLanguageStateKey } from './initial-language-state-key';
import { InitialLanguageState } from './initial-language-state.service';
import { CookieLanguageService } from '../language-providers/cookie-language.service';

@Injectable({ providedIn: 'root' })
export class LanguageHydrationService {
  #isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  #loggerService = inject(LoggerService);
  #transferState = inject(TransferState);
  #initialLanguage = inject(InitialLanguageState);
  #cookieLanguageService = inject(CookieLanguageService);

  init(): void {
    if (!this.#isBrowser) {
      return;
    }

    const language = this.#transferState.get(initialLanguageStateKey, '');

    if (!language) {
      this.#loggerService.error('[LanguageHydrationService] language not present in transfer state');
      return;
    }

    this.#initialLanguage.setLanguage(language);
    this.#cookieLanguageService.setLanguage(language);
  }
}
