import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';

import { USE_IN_MEMORY_CACHE } from '@ppg/core/cache-memory';
import { OCP_APIM_SUBSCRIPTION_KEY_HEADER } from '@ppg/core/constants';
import { ContentModelVersion, EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';
import { LoggerService } from '@ppg/core/logger';
import { Content, Footer, Resource } from '@ppg/core/models';

import { AppRoutesResponse } from './models/app-routes.model';
import { Header } from './models/header.model';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly environmentService: EnvironmentService = inject(EnvironmentService);
  private readonly loggerService: LoggerService = inject(LoggerService);

  public get contentApiBaseUrl(): string {
    return `${this.environmentService.getEnvironmentVariable(EnvVarsNames.APIM_CONTENT_API_BASE_URL)}`;
  }

  public get contentApiUrl(): string {
    const versionSegment = `${ContentModelVersion.V2}/`; // TBD: remove versionSegment when ContentApi updates contracts in scope of US 986553

    return `${this.contentApiBaseUrl}${versionSegment}`;
  }

  public get subscriptionKey(): string {
    return `${this.environmentService.getEnvironmentVariable(EnvVarsNames.APIM_CONTENT_API_SUBSCRIPTION_KEY)}`;
  }

  private get httpHeaders(): HttpHeaders {
    return new HttpHeaders().set(OCP_APIM_SUBSCRIPTION_KEY_HEADER, this.subscriptionKey);
  }

  private getHttpParams(paramsObj: { [param: string]: string | number }): HttpParams {
    return new HttpParams({ fromObject: paramsObj });
  }

  getContent(siteId: number, url_slug?: string, lang?: string): Observable<Content> {
    return this.http
      .get<Content>(`${this.contentApiUrl}sites/${siteId}/page`, {
        headers: this.httpHeaders,
        params: this.getHttpParams({
          ...(lang && { lang }),
          ...(url_slug && { url_slug }),
        }),
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(`[ContentService][getContent] Error occurred while fetching content: ${e.message}.`);
          return throwError(() => e);
        }),
      );
  }

  getPreviewContent(siteId: number, lang: string, codename: string) {
    return this.http
      .get<Content>(`${this.contentApiUrl}sites/${siteId}/page-preview`, {
        headers: this.httpHeaders,
        params: this.getHttpParams({
          ...(codename && { codename }),
          ...(lang && { lang }),
        }),
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(
            `[ContentService][getPreviewContent] Error occurred while fetching preview content: ${e.message}.`,
          );
          return throwError(() => e);
        }),
      );
  }

  getFooter(siteId: number, lang?: string): Observable<Footer> {
    return this.http
      .get<Footer>(`${this.contentApiUrl}sites/${siteId}/footer`, {
        headers: this.httpHeaders,
        params: this.getHttpParams({ ...(lang && { lang }) }),
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(`[ContentService][getFooter] Error occurred while fetching footer:${e.message}.`);
          return throwError(() => e);
        }),
      );
  }

  getHeader(siteId: number, lang?: string): Observable<Header> {
    return this.http
      .get<Header>(`${this.contentApiUrl}sites/${siteId}/header`, {
        headers: this.httpHeaders,
        params: this.getHttpParams({ ...(lang && { lang }) }),
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(`[ContentService][getHeader] Error occurred while fetching header: ${e.message}.`);
          return throwError(() => e);
        }),
      );
  }

  getResources(siteId?: number, lang?: string): Observable<Resource[]> {
    const params = this.getHttpParams({
      ...(siteId && { siteId }),
      ...(lang && { lang }),
    });
    const getResourcesUrlWithParams = `${this.contentApiUrl}resources?${params.toString()}`;
    return this.http
      .get<Resource[] | null>(getResourcesUrlWithParams, {
        context: new HttpContext().set(USE_IN_MEMORY_CACHE, getResourcesUrlWithParams),
        headers: this.httpHeaders,
      })
      .pipe(
        map((resources: Resource[] | null) => {
          return resources ?? [];
        }),
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(
            `[ContentService][getResources] Error occurred while fetching resources: ${e.message}.`,
          );
          return of([] as Resource[]);
        }),
      );
  }

  getAppRoutes(siteId: number, lang?: string): Observable<AppRoutesResponse> {
    const params = this.getHttpParams({ ...(lang && { lang }) });
    const appRouteApiUrl = `${this.contentApiUrl}sites/${siteId}/app-routes`;
    const getResourcesUrlWithParams = `${appRouteApiUrl}?${params.toString()}`;

    return this.http
      .get<AppRoutesResponse | null>(appRouteApiUrl, {
        context: new HttpContext().set(USE_IN_MEMORY_CACHE, getResourcesUrlWithParams),
        headers: this.httpHeaders,
        params,
      })
      .pipe(
        map((appRoutesResponse) => appRoutesResponse ?? []),
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(
            `[ContentService][getAppRoutes] Error occurred while fetching app routes: ${e.message}.`,
          );
          return of([]);
        }),
      );
  }
}
