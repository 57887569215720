export enum Locale {
  DE_DE = 'de-DE',
  EN_US = 'en-US',
  ES_ES = 'es-ES',
  ES_US = 'es-US',
  FR_CA = 'fr-CA',
  EN_CA = 'en-CA',
  SV_SE = 'sv-SE',
  NL_NL = 'nl-NL',
}
