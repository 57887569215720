import { PaginatorConfigModel } from '@ppg/core/models';

export const PAGINATOR_CONFIG_DEFAULT: PaginatorConfigModel = {
  first: 0,
  rows: 20,
  rowsPerPageOptions: [5, 10, 20, 50],
  currentPageReportTemplate: '{currentPage} / {totalPages}',
};

export const GLOBAL_BU_SEARCH_PAGE_PAGINATOR_CONFIG: PaginatorConfigModel = {
  first: PAGINATOR_CONFIG_DEFAULT.first,
  rows: PAGINATOR_CONFIG_DEFAULT.rows,
  rowsPerPageOptions: [5, 10, 15, 20],
  currentPageReportTemplate: PAGINATOR_CONFIG_DEFAULT.currentPageReportTemplate,
};

export enum GridPaginatorRows {
  ten = 10,
  twenty = 20,
  fifty = 50,
  hundred = 100,
}

export enum GridPaginatorPageLinkSizes {
  mobile = 2,
  default = 5,
}

export const GRID_PAGINATOR_CONFIG = {
  first: 0,
  rows: GridPaginatorRows.ten,
  rowsPerPageOptions: Object.values(GridPaginatorRows).filter((value) => !isNaN(value as number)),
};
