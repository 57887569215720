export enum ResourceItem {
  SEARCH_PAGE_PLACEHOLDER = 'search_page___placehoder',
  SEARCH_PAGE_ECOMMERCE_PLACEHOLDER = 'search_page___ecomm_placeholder',
  SEARCH_PAGE_ITEM_PER_PAGE = 'ppg_search_page_pager_item_per_page',
  SEARCH_PAGE_FILTER_RESULTS = 'ppg_search_page_filter_results',
  SEARCH_PAGE_FILTER_BY = 'ppg_search_page_filter_by',
  SEARCH_PAGE_REFINEMENTS = 'ppg_search_page_refinements',
  SEARCH_PAGE_CONTENT_TYPES = 'ppg_search_page_content_types',
  SEARCH_PAGE_GLOBAL_NO_RESULT_MESSAGE = 'ppg_search_page_global_no_result_message',
  SEARCH_PAGE_SEARCH_TIPS = 'ppg_search_page_search_tips',
  SEARCH_PAGE_TYPOS_MISTAKE_MESSAGE = 'ppg_search_page_typos_mistake_message',
  SEARCH_PAGE_USE_GENERAL_PRODUCT_TERMS_MESSAGE = 'ppg_search_page_use_general_product_terms_message',
  SEARCH_PAGE_SEARCH_ITEMS_AND_REFINE_RESULTS_MESSAGE = 'ppg_search_page_search_items_and_refine_results_message',
  SEARCH_PAGE_SPECIFIC_TERM_FROM_PRINTED_AD_MESSAGE = 'ppg_search_page_specific_term_from_printed_ad_message',
  SEARCH_PAGE_ADDITIONAL_ASSISTANCE = 'ppg_search_page_additional_assistance',
  SEARCH_PAGE_CALL = 'ppg_search_page_call',
  SEARCH_PAGE_SEND_US_MESSAGE = 'ppg_search_page_send_us_a_message',
  SEARCH_PAGE_ONLINE_CUSTOMER_SERVICE = 'ppg_search_page_online_customer_service',
  SEARCH_PAGE_TITLE = 'ppg_search_page_title',
  SEARCH_PAGE_RESULT = 'ppg_search_page_result',
  SEARCH_PAGE_RESULTS = 'ppg_search_page_results',
  SEARCH_PAGE_RESULT_FOR = 'ppg_search_page_result_for',
  SEARCH_PAGE_RESULTS_FOR = 'ppg_search_page_results_for',
  SEARCH_PAGE_GLOBAL_SEARCH_BUTTON = 'ppg_search_page_global_search_button',
  SEARCH_PAGE_CLEAR_FILTERS_BUTTON = 'ppg_search_page_clear_filters_button',
  SEARCH_PAGE_NO_FILTER_SELECTED = 'ppg_search_page_no_filters_selected',
  SEARCH_PAGE_ACTIVE_FILTER = 'ppg_search_page_active_filter',
  SEARCH_PAGE_ACTIVE_FILTERS = 'ppg_search_page_active_filters',
  SEARCH_PAGE_ACTIVE_FILTER_S = 'ppg_search_page_active_filter_s_',
  SEARCH_PAGE_ACTIVE = 'ppg_search_page_active',
  SEARCH_PAGE_GLOBAL_SEARCH_QUESTION = 'ppg_search_page_global_search_question',
  SEARCH_PAGE_NO_RESULTS_MESSAGE = 'ppg_search_page_no_results_message',
  SEARCH_PAGE_SEARCH_TEXT = 'ppg_search_page_search_text',
  SEARCH_PAGE_CONTACT_US_LINK = 'ppg_search_page_contact_us',
  SEARCH_PAGE_FILTERS = 'search_page_filters',
  SEARCH_PAGE_NO_FILTERS_SELECTED = 'search_page_no_filter_s__selected',
  SEARCH_PAGE_CLEAR_ALL_FILTERS = 'search_page_clear_all_filter_s_',
  SEARCH_PAGE_CLEAR_TOTAL_COUNT_SELECTED_FILTERS = 'search_page_clear_all__total_count_of_selected_fil',
  SEARCH_PAGE_SHOWING = 'article_list_page___global_bu_search_results_page_',
  SEARCH_PAGE_TO = 'article_list_page___global_bu_search_results_page__4c1ec9b',
  SEARCH_PAGE_OF = 'article_list_page___global_bu_search_results_page__4f317be',
  SEARCH_PAGE_ENTRIES = 'article_list_page___global_bu_search_results_page__907fb35',

  PAGINATION_ITEMS_PER_PAGE = 'ppg_pagination_items_per_page',
  PAGINATION_ITEMS = 'ppg_pagination_items',
  PAGINATION_ITEM = 'ppg_pagination_item',
  PAGINATION_FILTER_RESULTS = 'ppg_pagination_filter_results',
  PAGINATION_FILTER_BY = 'ppg_pagination_filter_by',
  PAGINATION_RESULTS = 'ppg_pagination_results',
  PAGINATION_RESULT = 'ppg_pagination_result',
  PAGINATION_NO_RESULTS = 'ppg_pagination_no_results',
  PAGINATION_BUTTON = 'pagination_button',

  PRODUCTS_TITLE = 'ppg_products_title',
  PRODUCTS_ALL = 'ppg_products_all',
  PRODUCTS_NAME = 'ppg_products_name',
  PRODUCTS_CATEGORY = 'ppg_products_category',
  PRODUCTS_PRODUCT_CODE = 'ppg_products_product_code',
  PRODUCTS_SAFETY_DATA_SHEET = 'ppg_products_safety_data_sheet',
  PRODUCTS_PRODUCT_DATA_SHEET = 'ppg_products_product_data_sheet',
  PRODUCTS_LINE_PAGE_TITLE = 'ppg_product_line_title',
  PRODUCTS_LINE_PAGE_CONTACT = 'ppg_product_line_contact',
  PRODUCTS_PREVIOUS_BTN = 'ppg_product_previous_button',
  PRODUCTS_NEXT_BTN = 'ppg_product_next_button',
  PRODUCTS_SDS = 'ppg_products_sds',
  PRODUCTS_PDS = 'ppg_products_pds',
  PRODUCTS_CATEGORIES = 'ppg_products_categories',
  PRODUCTS_FILTERS = 'ppg_products_filters',

  LEGAL_FOOTER = 'ppg_legal_footer_2',

  HEADER_ALL = 'ppg_header_all_button_text',
  HEADER_STORE_LOCATOR = 'ppg_header_store_locator',
  HEADER_GLOBAL = 'ppg_header_global_header_text',

  BREADCRUMB_HOME = 'ppg_breadcrumb_home',

  ERROR_MESSAGE_HEADER = 'ppg_error_message_header',
  ERROR_ENVIRONMENT_STG_PROD = 'ppg_error_environment_stg_prod',
  ERROR_ENVIRONMENT_DEV = 'ppg_error_environment_development',
  ERROR_NOT_FOUND_PAGE_TITLE = 'ppg_error_pagetitle_notfound',
  ERROR_NOT_FOUND_DESCRIPTION = 'ppg_error_app_not_found_description',

  MISC_PREVIOUS = 'ppg_misc_previous',
  MISC_NEXT = 'ppg_misc_next',
  MISC_RELOAD = 'ppg_misc_reload',
  MISC_PREVIEW = 'ppg_misc_preview',
  MISC_LANGUAGES = 'ppg_misc_languages',
  MISC_EN = 'ppg_misc_en',
  MISC_BACK = 'ppg_misc_back',

  LEAD_GEN_FIRST_NAME_ERROR_1 = 'ppg_lead_gen_first_name_error_1',
  LEAD_GEN_FIRST_NAME_ERROR_2 = 'ppg_lead_gen_first_name_error_2',
  LEAD_GEN_FIRST_NAME_ERROR_3 = 'ppg_lead_gen_first_name_error_3',
  LEAD_GEN_LAST_NAME_ERROR_1 = 'ppg_lead_gen_last_name_error_1',
  LEAD_GEN_LAST_NAME_ERROR_2 = 'ppg_lead_gen_last_name_error_2',
  LEAD_GEN_LAST_NAME_ERROR_3 = 'ppg_lead_gen_last_name_error_3',
  LEAD_GEN_EMAIL_ERROR_1 = 'ppg_lead_gen_email_error_1',
  LEAD_GEN_EMAIL_ERROR_2 = 'ppg_lead_gen_email_error_2',
  LEAD_GEN_EMAIL_ERROR_3 = 'ppg_lead_gen_email_error_3',
  LEAD_GEN_PHONE_NUMBER_ERROR_1 = 'ppg_lead_gen_phone_number_error_1',
  LEAD_GEN_PHONE_NUMBER_ERROR_2 = 'ppg_lead_gen_phone_number_error_2',
  LEAD_GEN_PHONE_NUMBER_ERROR_3 = 'ppg_lead_gen_phone_number_error_3',
  LEAD_GEN_COMPANY_NAME_ERROR_1 = 'ppg_lead_gen_company_name_error_1',
  LEAD_GEN_COMPANY_NAME_ERROR_2 = 'ppg_lead_gen_company_name_error_2',
  LEAD_GEN_REGION_ERROR = 'ppg_lead_gen_region_error',
  LEAD_GEN_QUESTION_ERROR = 'ppg_lead_gen_question_error',
  LEAD_GEN_SUBMIT_SUCCESS_MESSAGE = 'ppg_lead_gen_submit_success_message',
  LEAD_GEN_SUBMIT_ERROR_MESSAGE = 'ppg_lead_gen_submit_error_message',
  LEAD_GEN_INTRODUCTION = 'ppg_lead_gen_introduction',
  LEAD_GEN_SUB_HEADING = 'ppg_lead_gen_sub_heading',
  LEAD_GEN_HEADING = 'ppg_lead_gen_heading',
  LEAD_GEN_FORM_HEADING = 'ppg_lead_gen_form_heading',
  LEAD_GEN_BUSINESS_UNIT = 'ppg_lead_gen_business_unit',
  LEAD_GEN_BU_DEFAULT = 'ppg_lead_gen_default_place_holder',
  LEAD_GEN_BREADCRUMB = 'ppg_lead_gen_breadcrumb',

  PAGE_404_TITLE = 'ppg_title',
  PAGE_404_DESCRIPTION = 'ppg_description',

  STORE_LOCATOR_DISCLAIMER_DISMISS_BUTTON = 'ppg_store_locator_disclaimer_dismiss_button',
  STORE_LOCATOR_ENTER_LOCATION = 'ppg_store_locator_enter_location',
  STORE_LOCATOR_SEARCH_PLACEHOLDER = 'ppg_store_locator_search_placeholder',
  STORE_LOCATOR_MY_LOCATION_BUTTON = 'ppg_store_locator_my_location_button',
  STORE_LOCATOR_USE_MY_LOCATION_BUTTON = 'ppg_store_locator_use_my_location_button',
  STORE_LOCATOR_MY_FAVORITE_TAG = 'ppg_store_locator_my_favorite_tag',
  STORE_LOCATOR_SHOW_ON_MAP_BUTTON = 'ppg_store_locator_show_on_map_button',
  STORE_LOCATOR_SET_AS_FAVORITE_BUTTON = 'ppg_store_locator_set_as_favorite_button',
  STORE_LOCATOR_DIRECTION_BUTTON = 'ppg_store_locator_direction_button',
  STORE_LOCATOR_CALL_LOCATION_BUTTON = 'ppg_store_locator_call_location_button',
  STORE_LOCATOR_MORE_INFO_BUTTON = 'ppg_store_locator_more_info_button',
  STORE_LOCATOR_OPEN_UNTIL = 'ppg_store_locator_open_until',
  STORE_LOCATOR_STORE_HOURS = 'ppg_store_locator_store_hours',
  STORE_LOCATOR_STORE_FEATURES = 'ppg_store_locator_store_features',
  STORE_LOCATOR_DISTRIBUTOR_DESIGNATIONS = 'ppg_store_locator_distributor_designations',
  STORE_LOCATOR_NEXT_CLOSEST_STORE = 'ppg_store_locator_next_closest_store',
  STORE_LOCATOR_MORE_INFO_BACK_BUTTON = 'ppg_store_locator_back_to_store_locator',
  STORE_LOCATOR_RESULTS_TITLE = 'ppg_store_locator_results_title',
  STORE_LOCATOR_FILTERS = 'ppg_store_locator_filters',
  STORE_LOCATOR_FILTER_BY = 'ppg_store_locator_filter_by',
  STORE_LOCATOR_STORES_TYPES = 'ppg_store_locator_stores_types',
  STORE_LOCATOR_DISTRIBUTOR_TYPES = 'ppg_store_locator_distributor_types',
  STORE_LOCATOR_MILES_AWAY = 'ppg_store_locator_miles_away',
  STORE_LOCATOR_REFINISH_DISTRIBUTOR_TYPE_1 = 'ppg_store_locator_refinish_distributor_type_1',
  STORE_LOCATOR_ERROR = 'ppg_store_locator_error',
  STORE_LOCATOR_GEO_LOCATION_SERVICE_ERROR = 'ppg_store_locator_geo_location_service_error',
  STORE_LOCATOR_CLEAR_FILTERS = 'ppg_store_locator_clear_filters',

  USER_PROFILE_TITLE = 'ppg_user_profile_title',
  USER_PROFILE_SUBMIT_BUTTON = 'ppg_user_profile_submit_button',
  USER_PROFILE_FIRST_NAME = 'ppg_user_profile_first_name',
  USER_PROFILE_LAST_NAME = 'ppg_user_profile_last_name',
  USER_PROFILE_COMPANY_NAME = 'ppg_user_profile_company_name',
  USER_PROFILE_VIEW = 'ppg_user_profile_view_profile',

  USER_LOGIN = 'ppg_user_sign_in',
  USER_LOG_OUT = 'ppg_user_sign_out',
  USER_ROLE_DETAILS = 'ppg_user_role_details',

  ARTICLE_AUTHOR = 'ppg_article_page_author',
  ARTICLE_PUBLISHED = 'ppg_article_page_published',

  CARD_TYPE_VIDEO = 'ppg_card_type_video',
  CARD_TYPE_ASSET_URL = 'ppg_card_type_url',
  CARD_TYPE_DOCUMENT = 'ppg_card_type_document',
  CARD_TYPE_ARTICLE = 'ppg_card_type_article',

  FEDERATED_SEARCH_SEARCH_ALL = 'ppg_federated_search__search_all',
  FEDERATED_SEARCH_FOUND = 'ppg_federated_search__found',
  FEDERATED_SEARCH_PRODUCTS = 'ppg_federated_search__products',
  FEDERATED_SEARCH_COLORS = 'ppg_federated_search__colors',
  FEDERATED_SEARCH_ALL = 'ppg_federated_search__all',

  PPG_BROWSER_TAB_TITLE = 'ppg_browser_tab_tittle',

  SITE_SEARCH_PLACEHOLDER = 'search_placeholder',

  COLORS_FAVORITES_TITLE = 'colors_favorites_page_title',
  COLORS_FAVORITES_MY_LIST_SUBTITLE = 'colors_favorites_page_my_list',
  COLORS_FAVORITES_FILTERS_SUBTITLE = 'colors_favorites_page_filters',
  COLORS_FAVORITES_FILTER_SUBTITLE = 'colors_family_page_filter',
  COLORS_FAVORITES_SORT_BY = 'colors_favorites_page_sort_by',
  COLORS_FAVORITES_SORT_BY_NEWEST_FIRST = 'colors_favorites_page_newest_first',
  COLORS_FAVORITES_SORT_BY_OLDEST_FIRST = 'colors_favorites_page_oldest_first',
  COLORS_FAVORITES_SORT_BY_NAME_ASC = 'colors_favorites_page_name_asc',
  COLORS_FAVORITES_SORT_BY_NAME_DESC = 'colors_favorites_page_name_desc',

  CONFIRMATION_DIALOG_REMOVE_FAVORITE_TITLE = 'colors_favorites_page_remove_favourite_message_header',
  CONFIRMATION_DIALOG_REMOVE_FAVORITE_MESSAGE = 'colors_favorites_page_remove_favourite_message_body',
  CONFIRMATION_DIALOG_REMOVE_FAVORITE_CANCEL_BUTTON = 'colors_favorites_page_cancel_button',
  CONFIRMATION_DIALOG_REMOVE_FAVORITE_CONFIRM_BUTTON = 'colors_favorites_page_confirm_button',

  TOAST_REMOVE_FAVORITE_SUCCESS_CONFIRMATION_TITLE = 'colors_favorites_page_success_confirmation_header',
  TOAST_REMOVE_FAVORITE_SUCCESS_CONFIRMATION_MESSAGE = 'colors_favorites_page_success_confirmation_body',
  TOAST_ADD_FAVORITE_SUCCESS_CONFIRMATION_MESSAGE = 'colors_favorites_page_add_color_success_message',
  TOAST_REMOVE_FAVORITE_ERROR_CONFIRMATION_TITLE = 'colors_favorites_page_error_confirmation_header',
  TOAST_REMOVE_FAVORITE_ERROR_CONFIRMATION_MESSAGE = 'colors_favorites_page_error_confirmation_body',

  COLORS_FAVORITES_EMPTY_MESSAGE_TITLE = 'colors_favorites_page_empty_message_title',
  COLORS_FAVORITES_EMPTY_MESSAGE_CONTENT = 'colors_favorites_page_empty_message_content',
  COLORS_FAVORITES_ADD_COLOR_ERROR_MESSAGE = 'colors_favorites_page_add_color_error_message',
  COLORS_FAVORITES_COLOR_IMAGE_UNAVAILABLE = 'colors_favorites_page_color_image_unavailable',
  COLORS_FAVORITES_COLOR_NOT_AVAILABLE = 'colors_favorites_page_color_with_emg_number_unavailable',

  COLORS_FAMILIES_CATEGORY = 'colors_families_page_category',
  COLORS_FAMILIES_COLOR = 'colors_families_page_color',
  COLORS_FAMILIES_PRODUCT = 'colors_families_page_product',
  COLORS_FAMILIES_CATEGORY_SHADOW_MOST_POPULAR = 'colors_families_page_shadow_category_most_popular',
  COLORS_FAMILIES_CATEGORY_SHADOW_LIGHT = 'colors_families_page_shadow_category_light',
  COLORS_FAMILIES_CATEGORY_SHADOW_MIDTONE = 'colors_families_page_shadow_category_midtone',
  COLORS_FAMILIES_CATEGORY_SHADOW_DARK = 'colors_families_page_shadow_category_dark',
  COLORS_FAMILIES_SHADOWS_SECTION_HEADER = 'colors_families_page_shadows_section_header',
  COLORS_FAMILEIS_FAMILY_GROUP_HEADER_DEFAULT = 'colors_families_page_header',
  COLORS_FAMILEIS_FAMILY_GROUP_HEADER_INDOOR = 'colors_families_indoor_header',
  COLORS_FAMILEIS_FAMILY_GROUP_HEADER_OUTDOOR = 'colors_families_outdoor_header',

  COLORS_GRID_PAGINATION = 'colors_paginator',

  COLOR_DETAILS_BEST_SELLER_TAG = 'color_details_best_seller_tag',

  SEE_COLORS_USED = 'ppg_see_colors_used',

  PRODUCT_DETAILS_PRODUCT_DETAILS_HEADER = 'global_resource_item__product_details',
  PRODUCT_DETAILS_SUSTAINABILITY_HEADER = 'global_resource_item__sustainability',
  PRODUCT_DETAILS_DATA_SHEETS_HEADER = 'global_resource_item__data_sheets',
  PRODUCT_DETAILS_SAFETY_WARNING = 'safety_warning',
  PRODUCT_DETAILS_ACCORDION_SURFACES = 'surfaces',
  PRODUCT_DETAILS_ACCORDION_FINISH_TYPES = 'finish_types',
  PRODUCT_DETAILS_ACCORDION_INDOOR_OUTDOOR_USES = 'indoor_outdoor_uses',
  PRODUCT_DETAILS_ACCORDION_APPLICATION_METHODS = 'application_methods',
  PRODUCT_DETAILS_ACCORDION_PAINTED_OBJECTS = 'painted_objects',
  PRODUCT_DETAILS_ACCORDION_SAFETY_DATA_SHEETS = 'safety_data_sheets',
  PRODUCT_DETAILS_ACCORDION_TECH_DATA_SHEETS = 'tech_data_sheets',
  PRODUCT_DETAILS_ACCORDION_OTHER_DOCUMENTS = 'other_documents',
  PRODUCT_DETAILS_ACCORDION_SAFETY_DATA_SHEET_TYPE = 'safety_data_sheet_type',
  PRODUCT_DETAILS_ACCORDION_TECH_DATA_SHEET_TYPE = 'tech_data_sheet_type',
  PRODUCT_DETAILS_ACCORDION_CERTIFICATE_TYPE = 'certificate_type',
  PRODUCT_DETAILS_ACCORDION_PRODUCT_DECLARATION_TYPE = 'product_declaration_type',
  PRODUCT_DETAILS_ACCORDION_EPD_TYPE = 'epd_type',
  PRODUCT_DETAILS_FREQUENTLY_BOUGHT_HEADER = 'frequently_bought_with',

  PRODUCT_SUSTAINABILITY_FEATURES = 'sustainability_features',
  PRODUCT_SUSTAINABILITY_COMPOSITION = 'composition_base',
  PRODUCT_SUSTAINABILITY_VOC_LEVEL = 'voc_value',
  PRODUCT_SUSTAINABILITY_WASTE_DISPOSAL_ADVICE = 'waste_disposal_advice',
  PRODUCT_SUSTAINABILITY_TOOL_CLEANING = 'tool_cleaning_advice',

  COMPONENT_STEPS_STEP = 'component_steps_step',

  COLOR_DETAILS_COLOR_FAMILY_HEADLINE = 'color_details_color_family_headline',
  COLOR_DETAILS_COLLECTION_HEADLINE = 'color_details_color_collections_headline',
  COLOR_DETAILS_COLOR_DATA_HEADLINE = 'color_details_color_data_headline',
  COLOR_DETAILS_FIND_AND_BUY_SUPPORTED_PAINT_HEADLINE = 'find_and_buy_supported_paint',
  COLOR_DETAILS_VISUALIZE_THE_COLOR_HEADLINE = 'visualize_this_color_in_your_room',

  PRODUCT_LIST_PAGE_FILTERS = 'product_list_page___filters',
  PRODUCT_LIST_PAGE_RESULTS = 'product_list_page___results',
  PRODUCT_LIST_PAGE_DATASHEETS = 'product_list_page___datasheets',
  PRODUCT_LIST_PAGE_ALL_PRODUCTS = 'product_list_page___all_products',
  PRODUCT_LIST_PAGE_SEARCH_RESULTS_FOR = 'product_list_page___search_results_for',
  PRODUCT_LIST_PAGE_PRODUCTS = 'product_list_page___products',
  PRODUCT_LIST_PAGE_SHOWING = 'product_list_page___showing',
  PRODUCT_LIST_PAGE_TO = 'product_list_page___to',
  PRODUCT_LIST_PAGE_OF = 'product_list_page___of',
  PRODUCT_LIST_PAGE_ENTRIES = 'product_list_page___entries',
  PRODUCT_LIST_PAGE_CLEAR = 'product_list_page___clear',
  PRODUCT_LIST_PAGE_SEE_MORE = 'product_list_page___see_more',
  PRODUCT_LIST_PAGE_SEE_LESS = 'product_list_page___see_less',
  PRODUCT_LIST_PAGE_CODE = 'product_list_page___code',
  PRODUCT_LIST_PAGE_SHEEN = 'product_list_page___sheen',
  PRODUCT_LIST_PAGE_BASE = 'product_list_page___base',
  PRODUCT_LIST_PAGE_AVAILABLE_SIZES = 'product_list_page___available_sizes',
  PRODUCT_LIST_PAGE_SDS = 'product_list_page___sds',
  PRODUCT_LIST_PAGE_TDS = 'product_list_page___tds',
  PRODUCT_LIST_PAGE_CATEGORIES = 'product_list_page___categories',
  PRODUCT_LIST_PAGE_NO_PRODUCTS = 'product_list_page___no_products',
  PRODUCT_LIST_PAGE_FINISH_TYPE = 'product_list_page___finish_type',
  PRODUCT_LIST_PAGE_APPLICATION_METHOD = 'product_list_page___application_method',
  PRODUCT_LIST_PAGE_INDOOR_OUTDOOR_USE = 'product_list_page___indoor_outdoor_use',
  PRODUCT_LIST_PAGE_COMPOSITION_BASE = 'product_list_page___composition_base',
  PRODUCT_LIST_PAGE_CERTIFICATION_LABELS = 'product_list_page___certification_labels',
  PRODUCT_LIST_PAGE_INDOOR_AREA = 'product_list_page___indoor_area',
  PRODUCT_LIST_PAGE_PAINTED_OBJECT = 'product_list_page___painted_object',
  PRODUCT_LIST_PAGE_PAINTED_SURFACE_MATERIAL = 'product_list_page___painted_surface_material',
  PRODUCT_LIST_PAGE_SPECIAL_CHARACTERISTICS = 'product_list_page___special_characteristics',
  PRODUCT_LIST_PAGE_BRAND = 'product_list_page___brand',
  PRODUCT_LIST_PAGE_PRODUCT_LINES = 'product_list_page___product_lines',

  COLOR_LIST_PAGE_ALL_COLORS = 'color_list_page___all_colors',
  COLOR_LIST_PAGE_NO_COLORS = 'color_list_page___no_colors',
  COLOR_LIST_PAGE_TOO_MANY_RESULTS = 'color_list_page___too_many_results',

  COLOR_FAMILIES = 'color_families',
  COLOR_COLLECTIONS = 'color_collections',

  GLOBAL_SEE_LESS = 'ppg_see_less',
  GLOBAL_SEE_MORE = 'ppg_see_more',
  GLOBAL_TINTS_AND_SHADES = 'global_tints_and_shades',
  GLOBAL_COORDINATING_COLORS = 'coordinating_colors',

  RETURN_ITEMS_BUTTON = 'return_items_button',

  INVENTORY_DETAILS_AVAILABLE = 'available',
  INVENTORY_DETAILS_TO_BE_CONFIRMED = 'to_be_confirmed',
  INVENTORY_DETAILS_OUT_OF_STOCK = 'out_of_stock',
}
