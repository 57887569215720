export enum ContentComponentType {
  CARD_GENERAL = 'component_card',
  CARD_PAGE = 'component_card_page',
  CARD_ASSET_YOUTUBE_VIDEO = 'component_card_asset_youtube_video',
  CARD_ASSET_URL_CONTENT = 'component_card_asset_url',
  CARD_ASSET_DOCUMENT = 'component_card_asset_document',
  CENTERED_CONTENT_BLOCK = 'component_centered_content_block',
  RICH_TEXT = 'rich_text',
  SOCIAL_SHARING = 'component_social_sharing',
  ASSET_YOUTUBE_VIDEO = 'asset_youtube_video',
  CONTAINER_CONTENT_BANNER = 'container_content_banner',
  CONTENT_BANNER = 'component_content_banner',
  INLINE_IMAGE = 'component_inline_image',
  LEAD_GENERATION = 'component_lead_generation',
  FAQ = 'component_faq',
  CODE_IFRAME_WIDGET = 'code_iframe_widget',
  SECTION_GENERAL = 'section_general',
  ARTICLE_LIST_ITEM = 'component_article_list_item',
  ITEM_PERSON = 'item_person',
  CTA = 'component_cta',
  CONTAINER_CARD = 'container_card',
  CONTAINER_HIGH_IMAGE_STRIP = 'container_high_image_strip',
  CURATED_COLOR_PALETTE = 'curated_color_palette',
  COMPONENT_IMAGE_GALLERY = 'component_image_gallery',
  CONTAINER_IMAGE_GALLERY = 'container_image_gallery',
  EDGE_TO_EDGE = 'premium_component_edge_to_edge',
  COMPONENT_IMAGE_WITH_COLORS_USED = 'component_image_with_colors_used',
  COMPONENT_HERO_BANNER = 'premium_component_hero_banner',
  RESOURCE_LIST = 'component_resource_list',
  COMPONENT_603010_COLORS = 'component_603010_colors',
  CONTAINER_603010_COLORS = 'container_603010_colors',
  COMPONENT_STEPS = 'component_steps',
}
