import { HttpContextToken, HttpEvent, HttpInterceptorFn, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { of, tap } from 'rxjs';

import { CacheProviderService } from '../cache-provider.service';

export const USE_IN_MEMORY_CACHE = new HttpContextToken<string>(() => '');

export const cacheMemoryInterceptor: HttpInterceptorFn = (req, next) => {
  const cacheProviderService = inject(CacheProviderService);

  const useInMemoryCache = req.context.get(USE_IN_MEMORY_CACHE);

  if (useInMemoryCache) {
    const cachedValue = cacheProviderService.getValue<{ body: unknown }>(useInMemoryCache);

    if (cachedValue) {
      const response = new HttpResponse<unknown>({
        body: cachedValue.body,
        status: HttpStatusCode.Ok,
        statusText: 'OK',
        url: req.url,
      });
      return of(response);
    }
  }

  return next(req).pipe(
    tap((httpEvent: HttpEvent<unknown>) => {
      if (httpEvent instanceof HttpResponse && useInMemoryCache) {
        cacheProviderService.setValue(useInMemoryCache, { body: httpEvent.body });
      }
    }),
  );
};
