export enum ImageGalleryLayout {
  TWO_IMAGES_1 = 'two_images_template_1',
  TWO_IMAGES_2 = 'two_images_template_2',
  TWO_IMAGES_3 = 'two_images_template_3',
  THREE_IMAGES_1 = 'three_images_template_1',
  THREE_IMAGES_2 = 'three_images_template_2',
  THREE_IMAGES_3 = 'three_images_template_3',
  FOUR_IMAGES_1 = 'four_images_template_1',
  FOUR_IMAGES_2 = 'four_images_template_2',
  FOUR_IMAGES_3 = 'four_images_template_3',
  PUZZLE = 'puzzle_template',
}
