import { Route } from '@angular/router';

import { FeatureModule, AppRouteItem } from '@ppg/core/content';

import { FEATURE_MODULE_ROUTE_DATA_KEY } from '../constants/feature-route-data';
import { FeatureModuleRouteData } from '../models/feature-route-data.model';

export function getAppRouteItemFromRoute(data: Route['data']): AppRouteItem | null {
  const featureModule = data?.[FEATURE_MODULE_ROUTE_DATA_KEY] as FeatureModuleRouteData | null | undefined;
  return featureModule?.appRouteItem ?? null;
}

export function getPathByAppRouteFeatureModule(
  appRouteItems: AppRouteItem[],
  featureModule: FeatureModule,
): string | null {
  return getAppRoutePath(appRouteItems, (item) => item.featureModule === featureModule);
}

export function getPathByAppRouteCodename(appRouteItems: AppRouteItem[], codename: string): string | null {
  return getAppRoutePath(appRouteItems, (item) => item.codename === codename);
}

function getAppRoutePath(
  appRouteItems: AppRouteItem[],
  finder: (appRouteItems: AppRouteItem) => boolean,
): string | null {
  return appRouteItems.find(finder)?.url ?? null;
}
