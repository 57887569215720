import { DOCUMENT } from '@angular/common';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationStart, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';

import { ClientSiteSettingsKeys, Locale } from '@ppg/core/enums';
import { ErrorHandlerBaseService } from '@ppg/core/logger';
import { CurrentSite, Site, SiteLanguage } from '@ppg/core/models';

import { ConfigManagerService } from '../services/config-manager/config-manager.service';
import { SiteService } from '../services/site.service';
import { UrlService } from '../services/url/url.service';

@Injectable({ providedIn: 'root' })
export class CurrentSiteState extends ErrorHandlerBaseService {
  readonly #destroyRef = inject(DestroyRef);
  readonly #router = inject(Router);
  readonly #urlService = inject(UrlService);
  readonly #configurationManager = inject(ConfigManagerService);
  readonly #siteService = inject(SiteService);
  readonly #document = inject(DOCUMENT);

  readonly #isCurrentSiteInPreviewMode = signal<boolean>(false);

  readonly #rootSiteId = computed(() => this.#configurationManager.rootSite()?.id);
  readonly #currentSite = signal<CurrentSite>(null);
  readonly currentSite = this.#currentSite.asReadonly();

  readonly isPreviewMode = this.#isCurrentSiteInPreviewMode.asReadonly();
  readonly defaultLanguage = computed(
    () => this.siteLanguages().find((lang) => lang.isDefault)?.languageCulture ?? null,
  );
  readonly isRootSite = computed(() => !!this.currentSite()?.isRootSite);
  readonly siteId = computed(() => this.currentSite()?.id);
  readonly themeName = computed(() => this.currentSite()?.theme);
  readonly isECommerceEnabled = computed(() => this.getSiteFlag(ClientSiteSettingsKeys.EcommerceIsEnabled));
  readonly eCommerceIsAllowLogin = computed(() => this.getSiteFlag(ClientSiteSettingsKeys.EcommerceIsAllowLogin));
  readonly eCommerceUserProfileHideSecondaryEmail = computed(() =>
    this.getSiteFlag(ClientSiteSettingsKeys.EcommerceUserProfileHideSecondaryEmail),
  );
  readonly contentPageEnabled = computed(() => !this.isECommerceEnabled());
  readonly isCollectionSite = computed(() =>
    this.#siteService.isCollectionSite(this.currentSite(), this.#rootSiteId()),
  );
  readonly hubPagePath = computed(() => this.#siteService.getRootPagePath(this.currentSite(), this.#rootSiteId()));
  readonly rootName = computed(() => this.currentSite()?.rootName ?? null);
  readonly siteLanguages = computed(() => this.currentSite()?.siteLanguageList ?? []);
  readonly sortedSiteLanguages = computed(() => this.#getSortedSiteLanguages(this.siteLanguages()));
  readonly eCommerceJobsHideProducts = computed(() =>
    this.getSiteFlag(ClientSiteSettingsKeys.ECommerceJobsHideProducts),
  );

  constructor() {
    super('CurrentSiteState');
    this.#router.events
      .pipe(
        filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
        map(({ url }: NavigationStart) => url),
        distinctUntilChanged(),
        tap((url) => this.#updateData(url)),
        map(() => this.currentSite()?.rootName),
        distinctUntilChanged(),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe({
        next: (newRootName) =>
          this.loggerService.info(
            `[ConfigManagerService] Site root name changed. The new root name is: ${newRootName}`,
          ),
        error: () => {
          this.handleError('Error occurred while changing the site root name.');

          return this.#currentSite.set(null);
        },
      });

    const pathAndQuery = this.#document.location.pathname + this.#document.location.search;
    this.#updateData(pathAndQuery);
  }

  #updateData(url: string): void {
    this.#isCurrentSiteInPreviewMode.set(this.#urlService.getContentPreviewModeIndex(url) !== -1);
    this.#identifyCurrentSite(url);
  }

  #setCurrentSite(site: Site | null): void {
    const currentSite: Site | null | undefined = this.currentSite();
    if (currentSite === undefined || currentSite !== site) {
      this.#currentSite.set(site);
    }
  }

  getSiteSettingValueByKey(key: ClientSiteSettingsKeys): string {
    return this.#configurationManager.getSiteSettingValueByKey(this.currentSite(), key);
  }

  getSiteFlag(key: ClientSiteSettingsKeys): boolean {
    return this.#configurationManager.getSiteFlag(this.currentSite(), key);
  }

  getSiteName(): string {
    const currentSite: Site | null | undefined = this.currentSite();
    return currentSite?.name || '';
  }

  getSiteRootName(): string | null {
    const currentSite: Site | null | undefined = this.currentSite();

    if (!currentSite) return null;

    return this.isCollectionSite() ? currentSite.rootName : null;
  }

  getSiteSettingArrayValueByKey(key: ClientSiteSettingsKeys) {
    return this.#configurationManager.getSiteSettingValueByKey(this.#currentSite(), key).replaceAll(' ', '').split(',');
  }

  #identifyCurrentSite(url: string): void {
    const collectionName = this.#urlService.getCollectionName(url);
    this.#setCurrentSite(this.#configurationManager.identifyCurrentSite(collectionName, this.isPreviewMode()));
  }

  /**
   * First en-US if present, then other languages sorted by countryName + languageName
   */
  #getSortedSiteLanguages(languages: SiteLanguage[]): SiteLanguage[] {
    const { enUsLanguage, remainingLanguages } = languages.reduce<{
      enUsLanguage: SiteLanguage | null;
      remainingLanguages: SiteLanguage[];
    }>(
      (acc, lang) => {
        if (lang.languageCulture.toLowerCase() === Locale.EN_US.toLowerCase()) {
          acc.enUsLanguage = lang;
        } else {
          acc.remainingLanguages.push(lang);
        }

        return acc;
      },
      { enUsLanguage: null, remainingLanguages: [] },
    );

    const sortedLanguages = [...remainingLanguages].sort(
      (a: SiteLanguage, b: SiteLanguage) =>
        a.countryName.localeCompare(b.countryName) || a.languageName.localeCompare(b.languageName),
    );

    const groupedByFirstLetter = sortedLanguages.reduce((groups: { [key: string]: SiteLanguage[] }, language) => {
      const firstLetter: string = language.countryName[0].toUpperCase();

      groups[firstLetter] ??= [];
      groups[firstLetter].push(language);

      return groups;
    }, {});

    const sortedLanguagesByGroup = Object.keys(groupedByFirstLetter)
      .sort((a, b) => a.localeCompare(b))
      .reduce((acc, group) => [...acc, ...groupedByFirstLetter[group]], [] as SiteLanguage[]);

    return [...(enUsLanguage ? [enUsLanguage] : []), ...sortedLanguagesByGroup];
  }
}
