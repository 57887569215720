import { DOCUMENT, isPlatformServer } from '@angular/common';
import { computed, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, iif, map, Observable, of, switchMap, tap, timeout } from 'rxjs';

import { CurrentSiteState, ConfigService } from '@ppg/configuration';
import { FALLBACK_FAVICON_SRC } from '@ppg/core/constants';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { LoggerService } from '@ppg/core/logger';
import { StaticAsset } from '@ppg/core/models';

@Injectable({
  providedIn: 'root',
})
export class StaticContentService {
  readonly #configService = inject(ConfigService);
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #platformId = inject(PLATFORM_ID);
  readonly #document = inject(DOCUMENT);
  readonly #loggerService = inject(LoggerService);

  readonly #contentSiteParams = computed(() => {
    return {
      siteId: this.#currentSiteState.siteId(),
      language: this.#currentSiteState.defaultLanguage() ?? undefined,
    };
  });

  readonly #faviconCodeName = computed(() =>
    this.#currentSiteState.getSiteSettingValueByKey(ClientSiteSettingsKeys.BrandingAssetsFavicon),
  );

  readonly #globalStaticAssets$ = this.#configService.getGlobalAssetsConfig().pipe(map((data) => data?.items ?? []));
  readonly #siteStaticAssets$ = toObservable(this.#contentSiteParams).pipe(
    switchMap((params) => {
      if (!params.siteId) {
        return of([]);
      }

      return this.#configService
        .getStaticSiteConfig(params.siteId, params.language)
        .pipe(map((staticAssets) => staticAssets?.items ?? []));
    }),
  );

  readonly #siteStaticAssets = toSignal(this.#siteStaticAssets$);
  readonly #globalStaticAssets = toSignal(this.#globalStaticAssets$);

  getSiteStaticAsset(setting: ClientSiteSettingsKeys): StaticAsset | undefined {
    return this.#findStaticAsset(setting, this.#siteStaticAssets());
  }

  getGlobalStaticAsset(setting: ClientSiteSettingsKeys): StaticAsset | undefined {
    return this.#findStaticAsset(setting, this.#globalStaticAssets());
  }

  getStaticAsset(setting: ClientSiteSettingsKeys): StaticAsset | undefined {
    return this.getSiteStaticAsset(setting) ?? this.getGlobalStaticAsset(setting);
  }

  loadStaticContent(): Observable<void> {
    return iif(
      () => isPlatformServer(this.#platformId),
      this.#siteStaticAssets$.pipe(
        timeout(1000),
        tap((assets) => {
          const faviconAsset = assets?.find((asset) => asset.codename === this.#faviconCodeName());
          const faviconSrc = faviconAsset?.url ?? FALLBACK_FAVICON_SRC;

          this.#loadFavicon(faviconSrc);
        }),
        catchError((e) => {
          this.#loggerService.error(
            `[StaticContentService] Error during the initialization of the common static content: ${e}`,
          );

          return of(undefined);
        }),
      ),
      of(undefined),
    ) as Observable<void>;
  }

  #loadFavicon(faviconSrc: string): void {
    const faviconElement = this.#getFaviconElement();

    if (faviconElement && faviconElement.href.includes(faviconSrc)) {
      return;
    }

    if (faviconElement) {
      faviconElement.href = faviconSrc;
    } else {
      const head = this.#document.querySelector('head')!;
      const faviconLink = this.#document.createElement('link');

      faviconLink.id = 'ppg-favicon';
      faviconLink.type = 'image/x-icon';
      faviconLink.rel = 'icon';
      faviconLink.href = faviconSrc;

      head.insertAdjacentElement('beforeend', faviconLink);
    }
  }

  #getFaviconElement(): HTMLLinkElement {
    return this.#document.getElementById('ppg-favicon') as HTMLLinkElement;
  }

  #findStaticAsset(setting: ClientSiteSettingsKeys, assets: StaticAsset[] | null | undefined): StaticAsset | undefined {
    const codeName = this.#currentSiteState.getSiteSettingValueByKey(setting);

    return assets?.find((asset) => asset.codename === codeName);
  }
}
