export enum CaslSubject {
  ALL = 'all',
  CART = 'Cart',
  CUSTOMER = 'Customer',
  DELIVERY_ADDRESS = 'DeliveryAddress',
  EXTERNAL_ACCOUNT = 'ExternalAccount',
  FULFILLMENT_CONTACT = 'FulfillmentContact',
  GLOBAL_SETTINGS = 'GlobalSettings',
  IMPORT = 'Import',
  JOB = 'Job',
  SPECIFIED_JOB_ITEM = 'SpecifiedJobItem',
  ORDER = 'Order',
  ORDER_DASHBOARD = 'OrderDashboard',
  ORDER_SETTINGS = 'OrderSettings',
  EXPORT_ORDER_HISTORY = 'ExportOrderHistory',
  STORE_ORDER_DETAILS = 'StoreOrderDetails',
  PRICE = 'Price',
  USER = 'User',
  CUSTOMER_USERS = 'CustomerUser',
  PAYMENT_METHODS = 'PaymentMethods',
  PAY_MY_BILL = 'PayMyBill',
}
