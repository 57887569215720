export enum AppBreakpoints {
  MIN_WIDTH_DESKTOP = 1000,
}

export const enum Breakpoints {
  Mobile = '(max-width: 767.98px)',
  Tablet = '(min-width: 768px)',
  Desktop = '(min-width: 1280px)',
}

export enum BreakpointsPx {
  Desktop = 1280,
  Tablet = 768,
  Mobile = 320,
}
