import { InjectionToken, Signal } from '@angular/core';
import { ActivatedRouteSnapshot, GuardResult, MaybeAsync, Route, RouterStateSnapshot } from '@angular/router';

import { FeatureModule } from '@ppg/core/content';

export type GuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => MaybeAsync<GuardResult>;

export interface RouterConfigurator {
  language: Signal<string>;
  getFeatureModuleRoute(featureModule: FeatureModule): Route[];
  getAuthGuards(featureModule: FeatureModule): GuardFn[];
  getStaticRoutes(): Route[];
}

export const ROUTER_CONFIGURATOR = new InjectionToken<RouterConfigurator>('ROUTER_CONFIGURATOR');
