import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { ROUTER_CONFIGURATOR, RouterConfigurator } from './router-configurator';

export function provideDynamicNavigation(routerConfiguratorProvider: () => RouterConfigurator): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ROUTER_CONFIGURATOR,
      useFactory: routerConfiguratorProvider,
    },
  ]);
}
