import { CookieOptions } from 'express';

export const DAYS_UNTIL_EXPIRE = 30;

export const FAVORITE_STORE_COOKIE_KEY = 'distributor-locator|favorite-store';
export const LANGUAGE_COOKIES_KEY = 'lang_locale';

export const DEFAULT_COOKIES_OPTIONS = {
  expires: DAYS_UNTIL_EXPIRE,
  path: '/',
  sameSite: 'Strict',
  secure: true,
} satisfies {
  expires?: number | Date;
  path?: string;
  sameSite?: 'Lax' | 'None' | 'Strict';
  secure: boolean;
};

export const DEFAULT_HTTP_ONLY_COOKIES_OPTIONS: CookieOptions = {
  httpOnly: true,
  secure: true,
  sameSite: 'strict',
} as const satisfies CookieOptions;
