import { APP_INITIALIZER, inject, makeEnvironmentProviders } from '@angular/core';

import { EnvTransferService } from './env-transfer.service';

export function provideEnvironmentConfig() {
  return makeEnvironmentProviders([
    EnvTransferService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const envTransferService = inject(EnvTransferService);
        return () => envTransferService.initTransport();
      },
    },
  ]);
}
