import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, switchMap, take, tap, throwError } from 'rxjs';

import { CurrentSiteState } from '@ppg/configuration';
import { DISABLE_TOKEN_REFRESH } from '@ppg/core/constants';
import { FeatureModule } from '@ppg/core/content';
import { AuthStaticRoutes } from '@ppg/core/enums';
import { LoggerService } from '@ppg/core/logger';
import { AppRoutesService } from '@ppg/core/navigation';
import { onCancel } from '@ppg/core/utils';

import { AuthApiService } from '../services/auth-api/auth-api.service';
import { urlContainsSegment } from '../utils/auth-helpers/auth-helpers.utils';

let isRefreshing = false;
const refreshSubject = new BehaviorSubject<boolean>(false);

export const refreshTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authApiService = inject(AuthApiService);
  const router = inject(Router);
  const logger = inject(LoggerService);
  const currentSiteState = inject(CurrentSiteState);
  const appRoutesService = inject(AppRoutesService);

  return next(req).pipe(
    catchError((error) => {
      const disableRefresh = req.context.get(DISABLE_TOKEN_REFRESH);
      const unauthorisedError = error.status === HttpStatusCode.Unauthorized;
      const authUrl = urlContainsSegment(req.url, '/auth/');
      const isContentPage = currentSiteState.contentPageEnabled();
      const skipRefresh = isContentPage || disableRefresh || authUrl || !unauthorisedError;

      if (skipRefresh) {
        return throwError(() => error);
      }

      if (isRefreshing) {
        return refreshSubject.pipe(
          filter(Boolean),
          take(1),
          switchMap(() => next(req)),
        );
      }

      logger.info('Refreshing token...');
      isRefreshing = true;
      refreshSubject.next(false);

      return authApiService.refreshToken().pipe(
        tap({
          next: () => {
            isRefreshing = false;
            refreshSubject.next(true);
          },
          error: () => {
            isRefreshing = false;
            refreshSubject.next(false);
          },
        }),
        catchError((err: HttpErrorResponse) => {
          const route = `${appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.Authentication)}/${AuthStaticRoutes.LOGIN}`;
          logger.warn(`Navigating to ${route} due to refreshing token error: ${error.message}`);
          void router.navigate([route]);

          return throwError(() => err);
        }),
        switchMap(() => next(req)),
        onCancel(() => {
          isRefreshing = false;
          refreshSubject.next(false);
        }),
      );
    }),
  );
};
