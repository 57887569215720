import { inject, Injectable } from '@angular/core';
import { defer, iif, map, Observable, of, switchMap } from 'rxjs';

import { FeatureModule } from '@ppg/core/content';
import { Color } from '@ppg/core/models';
import { UrlFormatter } from '@ppg/shared/utils';

import { AppRoutesService } from '../services/app-routes.service';

@Injectable({ providedIn: 'root' })
export class FeatureRoutePathService {
  readonly #appRoutesService = inject(AppRoutesService);
  readonly #urlFormatter = inject(UrlFormatter);

  getContactUsFormPath$(): Observable<string | null> {
    return of(this.#appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.ContactUsForm)).pipe(
      switchMap((contactUsPath) =>
        iif(
          () => !!contactUsPath,
          of(contactUsPath),
          defer(() => this.#appRoutesService.getRootPathByAppRouteFeatureModule$(FeatureModule.ContactUsForm)),
        ),
      ),
    );
  }

  getContactUsPath$(): Observable<string | null> {
    return this.getContactUsFormPath$().pipe(
      map((contactUsPath) => contactUsPath?.split('/').slice(0, -1).join('/') ?? null),
    );
  }

  getGlobalSearchPath(query: string): string | null {
    const searchPath = this.#appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.GlobalSearch);
    if (!searchPath) {
      return null;
    }

    return this.#getPathWithQuery(searchPath, query);
  }

  getRootGlobalSearchPath$(query: string): Observable<string | null> {
    return this.#appRoutesService
      .getRootPathByAppRouteFeatureModule$(FeatureModule.GlobalSearch)
      .pipe(map((searchPath) => (searchPath ? this.#getPathWithQuery(searchPath, query) : null)));
  }

  getColorsPath(query?: string): string | null {
    const colorsPath = this.#appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.Colors);
    if (!colorsPath) {
      return null;
    }

    return query ? this.#getPathWithQuery(colorsPath, query) : colorsPath;
  }

  getProductJourneyListPath(query?: string): string | null {
    const productJourneyListPath = this.#appRoutesService.getPathByAppRouteFeatureModule(
      FeatureModule.ProductJourneyList,
    );

    if (!productJourneyListPath) {
      return null;
    }

    return query ? this.#getPathWithQuery(productJourneyListPath, query) : productJourneyListPath;
  }

  getColorDetailsPath({ colorName, productNumber }: Color): string {
    const colorDetailsPath = this.#appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.ColorDetails);

    return this.#urlFormatter.formatStringValueToUrlPath(`${colorDetailsPath}/${colorName}/${productNumber}`);
  }

  #getPathWithQuery(path: string, query: string): string {
    return `${path}?q=${encodeURIComponent(query)}`;
  }
}
