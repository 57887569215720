import { Injectable } from '@angular/core';

import { Site } from '@ppg/core/models';

@Injectable({ providedIn: 'root' })
export class SiteService {
  public isCollectionSite(site: Site | null | undefined, rootSiteId?: number | null | undefined): boolean {
    return (
      !!site &&
      site.isActive &&
      !site.canUseOwnDomain &&
      !site.isRootSite &&
      !!rootSiteId &&
      site?.rootDomainSiteId === rootSiteId
    );
  }

  public getRootPagePath(site: Site | null | undefined, rootSiteId?: number | null | undefined): string | null {
    if (!site) {
      return null;
    }

    if (this.isCollectionSite(site, rootSiteId)) {
      return `/${site.rootName}`;
    }

    return '/';
  }
}
