import { inject, Injectable } from '@angular/core';

import { Site } from '@ppg/core/models';

import { CurrentSiteState } from '../states/current-site.state';
import { ConfigManagerService } from './config-manager/config-manager.service';
import { UrlCreatorService } from './url-creator/url-creator.service';

@Injectable({ providedIn: 'root' })
export class CurrentSiteService {
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly configManagerService = inject(ConfigManagerService);
  private readonly urlCreatorService = inject(UrlCreatorService);

  isCurrentSiteDomainUrl(url: string): boolean {
    const currentSite = this.currentSiteState.currentSite();

    if (!currentSite) {
      return false;
    }

    const urlToCheck = new URL(url);

    if (currentSite.canUseOwnDomain || currentSite.isRootSite) {
      return this.isSiteUrl(currentSite, urlToCheck);
    }

    return this.isSiteUrl(currentSite, urlToCheck) || this.isRootSiteUrl(urlToCheck);
  }

  public buildPagePath(lang: string, pageUrl: string, pageHierarchy?: string): string {
    const rootName = this.currentSiteState.getSiteRootName();

    return this.urlCreatorService.createPageUrl(lang, rootName, pageHierarchy, pageUrl);
  }

  private isRootSiteUrl(url: URL): boolean {
    const rootSite = this.configManagerService.rootSite();

    return this.isSiteUrl(rootSite, url);
  }

  private isSiteUrl(site: Site | null, url: URL): boolean {
    return site?.domainNames?.some((domain) => domain.domainNameValue === url.hostname) || false;
  }
}
