import { Injectable, StateKey, TransferState, inject, makeStateKey } from '@angular/core';

import { ENVIRONMENT_VARIABLES_KEY } from '@ppg/core/constants';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly transferState: TransferState = inject(TransferState);
  private readonly stateKey: StateKey<string> = makeStateKey<string>(ENVIRONMENT_VARIABLES_KEY);

  getEnvironmentVariable(key: string, defaultValue: string | null = null): string | null {
    const serializedVariables = this.transferState.get(this.stateKey, null);

    if (serializedVariables) {
      try {
        const variables = new Map<string, string>(JSON.parse(serializedVariables));
        return variables.get(key) || defaultValue;
      } catch {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  }
}
