import { TableColumnType } from '@ppg/core/enums';
import { TableHeaderModel } from '@ppg/core/models';

export const ORDER_TABLE_HEADERS: TableHeaderModel[] = [
  {
    id: 'orderDate',
    title: 'ORDER_DATE',
    type: TableColumnType.DATE,
  },
  {
    id: 'orderNumber',
    title: 'ORDER_NUMBER',
    type: TableColumnType.STRING,
  },
  {
    id: 'customerName',
    title: 'CUSTOMER_NAME',
    type: TableColumnType.STRING,
  },
  {
    id: 'orderTotal',
    title: 'ORDER_TOTAL',
    type: TableColumnType.CURRENCY,
  },
  {
    id: 'fulfillmentDate',
    title: 'FULFILLMENT_DATE',
    type: TableColumnType.DATE,
  },
];
