export enum Permission {
  // Customers
  VIEW_CUSTOMERS = 'view_customers',
  MANAGE_CUSTOMERS = 'manage_customers',

  // Jobs
  SELECT_JOB = 'select_job',
  VIEW_JOBS = 'view_jobs',
  VIEW_INACTIVE_JOBS = 'view_inactive_jobs',
  CREATE_JOBS = 'create_jobs',
  MANAGE_JOBS = 'manage_jobs',
  MANAGE_ALL_JOBS = 'manage_all_jobs',
  COPY_JOBS = 'copy_jobs',
  REQUEST_ACCESS_ALL_JOBS = 'request-access_all_jobs',

  // Specified job items
  VIEW_SPECIFIED_JOB_ITEMS = 'view_specd_job_items',
  MANAGE_SPECIFIED_JOB_ITEMS = 'manage_specd_job_items',

  // Fulfillment Contacts
  ADD_ALL_FULFILLMENT_CONTACTS = 'add_all_fulfillment-contacts',
  ADD_FULFILLMENT_CONTACTS_FOR_DEMO_JOBS = 'add_all.jobs:demo_fulfillment-contacts',
  ADD_CUSTOMER_FULFILLMENT_CONTACTS = 'add_customer_fulfillment-contacts',
  ADD_FULFILLMENT_CONTACTS_AS_SALES_REP = 'add_customer.job:as-sales-rep_fulfillment-contacts',
  ADD_FULFILLMENT_CONTACTS_AS_JOB_USER = 'add_customer.job:as-job-user_fulfillment-contacts',
  DELETE_ALL_FULFILLMENT_CONTACTS = 'delete_all_fulfillment-contacts',
  DELETE_FULFILLMENT_CONTACTS_FOR_DEMO_JOBS = 'delete_all.jobs:demo_fulfillment-contacts',
  DELETE_CUSTOMER_FULFILLMENT_CONTACTS = 'delete_customer_fulfillment-contacts',
  DELETE_FULFILLMENT_CONTACTS_AS_SALES_REP = 'delete_customer.job:as-sales-rep_fulfillment-contacts',
  DELETE_FULFILLMENT_CONTACTS_AS_JOB_USER = 'delete_customer.job:as-job-user_fulfillment-contacts',
  EDIT_ALL_FULFILLMENT_CONTACTS = 'edit_all_fulfillment-contacts',
  EDIT_FULFILLMENT_CONTACTS_FOR_DEMO_JOBS = 'edit_all.jobs:demo_fulfillment-contacts',
  EDIT_CUSTOMER_FULFILLMENT_CONTACTS = 'edit_customer_fulfillment-contacts',
  EDIT_FULFILLMENT_CONTACTS_AS_SALES_REP = 'edit_customer.job:as-sales-rep_fulfillment-contacts',
  EDIT_FULFILLMENT_CONTACTS_AS_JOB_USER = 'edit_customer.job:as-job-user_fulfillment-contacts',

  // Delivery Addresses
  ADD_ALL_DELIVERY_ADDRESSES = 'add_all_delivery-addresses',
  ADD_DELIVERY_ADDRESSES_FOR_DEMO_JOBS = 'add_all.jobs:demo_delivery-addresses',
  ADD_CUSTOMER_DELIVERY_ADDRESSES = 'add_customer_delivery-addresses',
  ADD_DELIVERY_ADDRESSES_AS_SALES_REP = 'add_customer.job:as-sales-rep_delivery-addresses',
  ADD_DELIVERY_ADDRESSES_AS_JOB_USER = 'add_customer.job:as-job-user_delivery-addresses',
  DELETE_ALL_DELIVERY_ADDRESSES = 'delete_all_delivery-addresses',
  DELETE_DELIVERY_ADDRESSES_FOR_DEMO_JOBS = 'delete_all.jobs:demo_delivery-addresses',
  DELETE_CUSTOMER_DELIVERY_ADDRESSES = 'delete_customer_delivery-addresses',
  DELETE_DELIVERY_ADDRESSES_AS_SALES_REP = 'delete_customer.job:as-sales-rep_delivery-addresses',
  DELETE_DELIVERY_ADDRESSES_AS_JOB_USER = 'delete_customer.job:as-job-user_delivery-addresses',
  EDIT_ALL_DELIVERY_ADDRESSES = 'edit_all_delivery-addresses',
  EDIT_DELIVERY_ADDRESSES_FOR_DEMO_JOBS = 'edit_all.jobs:demo_delivery-addresses',
  EDIT_CUSTOMER_DELIVERY_ADDRESSES = 'edit_customer_delivery-addresses',
  EDIT_DELIVERY_ADDRESSES_AS_SALES_REP = 'edit_customer.job:as-sales-rep_delivery-addresses',
  EDIT_DELIVERY_ADDRESSES_AS_JOB_USER = 'edit_customer.job:as-job-user_delivery-addresses',
  MANAGE_DELIVERY_ADDRESSES = 'manage_delivery_addresses',
  VIEW_DELIVERY_ADDRESSES = 'view_delivery_addresses',

  // Orders
  CREATE_ORDERS = 'create_orders',
  VIEW_ORDERS = 'view_orders',
  VIEW_STORE_ORDERS = 'view_store_orders',
  VIEW_STORE_ORDER_DETAILS = 'view_store_order_details',
  VIEW_EXPORT_ORDER_HISTORY = 'view_export_order_history',
  CREATE_ORDERS_DASHBOARD = 'create_orders_dashboard',

  // Users
  MANAGE_ALL_USERS = 'manage_all_users',
  MANAGE_CUSTOMER_USERS = 'manage_customer_users',
  VIEW_ALL_USERS = 'view_all_users',
  VIEW_CUSTOMER_USERS = 'view_customer_users',

  // Profile Notification Preferences
  VIEW_PROFILE_NOTIFICATION_PREFERENCES = 'view_myprofile_notification-preferences',

  // Prices
  VIEW_PRICES = 'view_prices',

  // Accounts
  IMPORT_EXTERNAL_ACCOUNTS = 'import_external_accounts',
  VIEW_EXTERNAL_ACCOUNTS = 'view_external_accounts',

  // Imports
  MANAGE_IMPORTS = 'manage_imports',
  VIEW_IMPORTS = 'view_imports',

  // Dropdown For Custom Color
  VIEW_CUSTOM_COLOR_TINTBASE = 'view_custom_color_tintbase',

  // Admin Page
  VIEW_ADMIN_PAGE = 'view_admin_page',

  // View payment methods
  VIEW_PAYMENT_METHODS = 'view_payment_methods',

  // View Pay My Bill Menu
  VIEW_PAY_MY_BILL = 'view_pay_my_bill',
}
