import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs';

import { FeatureModule } from '@ppg/core/content';
import { AppRoutesService } from '@ppg/core/navigation';

import { UserService } from '../services/user/user.service';

/**
 * Guard checks if user is active if not redirects to /inactive-user
 */
export const isUserActiveGuard = () => {
  const router = inject(Router);
  const userService = inject(UserService);
  const appRoutesService = inject(AppRoutesService);

  const inactiveUserUrl = appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.InactiveUser);

  return userService.loaded$.pipe(
    filter(Boolean),
    map(() => userService.isActive() || router.createUrlTree([inactiveUserUrl])),
  );
};
