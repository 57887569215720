import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, forkJoin, identity, map, Observable, of, switchMap, take } from 'rxjs';

import { AuthService, UserService } from '@ppg/auth';
import { CurrentSiteState, SlugService, UrlService } from '@ppg/configuration';
import { LoggerService } from '@ppg/core/logger';

import { UrlLanguageService } from './language-providers/url-language.service';

/**
 * Params for {@link Router.navigateByUrl} function calls
 * NOTE: Supported only string based urls
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
export type NavigateParams<T = Parameters<Router['navigateByUrl']>> = T extends [infer Url, ...infer RestParams]
  ? [string, ...RestParams]
  : never;

export interface ChangeFlow<T = NavigateParams> {
  urlUpdater: (params: NavigateParams) => T;
}

export interface LanguageChangeParams {
  currentUrl: string;
  oldLanguage: string;
  newLanguage: string;
}

export abstract class FeatureModuleChangeFlow {
  abstract getChangeFlow(changeParams: LanguageChangeParams): Observable<ChangeFlow>;
  abstract canApplyChangeFlow(): boolean;
}

@Injectable({ providedIn: 'root' })
export class LanguageChangeService {
  readonly #logger = inject(LoggerService);
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #slugService = inject(SlugService);
  readonly #urlService = inject(UrlService);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #urlLanguageService = inject(UrlLanguageService);
  readonly #featureModuleChangeFlowService = inject(FeatureModuleChangeFlow);

  readonly #changeFlows = {
    authorized: (changeParams) =>
      this.#userLanguageUpdateChangeFlow(changeParams).pipe(
        switchMap((changeFlow) =>
          this.#combineChangeFlowsObservables([
            of(changeFlow),
            this.#getLocalizedUrlChangeFlow(changeParams),
            this.#urlUpdateChangeFlow(changeParams),
            /**
             * Authorized flow does not allow to switch language via URL, we need to replace previous history state
             * to avoid back <-> forward navigation loop when user uses native browser navigation
             */
            this.#replacePreviousUrlChangeFlow(),
          ]),
        ),
        catchError(() => {
          this.#logger.error(
            '[LanguageChangeService][userLanguageUpdateChangeFlow] unable to update user language, request failed, skipping language update ',
          );
          return of({ urlUpdater: () => null });
        }),
      ),
    anonymous: (changeParams) =>
      this.#combineChangeFlowsObservables([
        this.#getLocalizedUrlChangeFlow(changeParams),
        this.#urlUpdateChangeFlow(changeParams),
      ]),
  } as const satisfies Record<
    string,
    (changeParams: LanguageChangeParams) => Observable<ChangeFlow<NavigateParams | null>>
  >;

  /**
   * Returns navigation params that can be used for {@link Router.navigateByUrl}.
   *
   * Method will return null in the following cases:
   * - If update user language request failed
   * - If new language is empty
   * - If new language equals to old language
   *
   * Example authorized flow:
   * ```
   * oldLanguage = en-US
   * newLanguage = es-US
   * currentUrl = /en-US/products
   * isAuthenticated = true
   * Result: navigationParams ['/es-US/products', { replaceUrl: true }]
   * ```
   *
   * Example anonymous flow:
   * ```
   * oldLanguage = en-US
   * newLanguage = fr-CA
   * currentUrl = /en-US/refinish/products
   * isAuthenticated = false
   * Result: navigationParams ['/fr-CA/refinish/produits', {}]
   * ```
   */
  getLanguageChangeNavigation(changeParams: LanguageChangeParams): Observable<NavigateParams | null> {
    const { oldLanguage, newLanguage, currentUrl } = changeParams;
    if (!newLanguage) {
      this.#logger.error(
        '[LanguageChangeService][changeLanguage] attempt to update language with empty language, skipping',
      );
      return of(null);
    }

    if (newLanguage === oldLanguage) {
      this.#logger.warn('[LanguageChangeService][changeLanguage] language is the same skipping language update');
      return of(null);
    }

    this.#logger.info(
      `[LanguageChangeService][changeLanguage] Starting language switch from ${oldLanguage} to ${newLanguage}`,
    );

    return this.#getChangeFlow(changeParams).pipe(
      take(1),
      map(({ urlUpdater }) => urlUpdater([currentUrl, {}])),
    );
  }

  #getChangeFlow(changeParams: LanguageChangeParams): Observable<ChangeFlow<NavigateParams | null>> {
    if (this.#authService.isAuthenticated()) {
      this.#logger.info('[LanguageChangeService][getChangeFlow] User is authenticated, using authorized change flow');
      return this.#changeFlows.authorized(changeParams);
    }

    this.#logger.info('[LanguageChangeService][getChangeFlow] using anonymous change flow');
    return this.#changeFlows.anonymous(changeParams);
  }

  #urlUpdateChangeFlow(changeParams: LanguageChangeParams): Observable<ChangeFlow> {
    return of({
      urlUpdater: ([url, extras]) => [this.#urlLanguageService.generateUrl(url, changeParams.newLanguage), extras],
    });
  }

  #replacePreviousUrlChangeFlow(): Observable<ChangeFlow> {
    return of({
      urlUpdater: ([url, extras]) => [url, { ...extras, replaceUrl: true }],
    });
  }

  #userLanguageUpdateChangeFlow(changeParams: LanguageChangeParams): Observable<ChangeFlow> {
    return this.#userService.updateLanguage(changeParams.newLanguage).pipe(
      take(1),
      map(() => ({ urlUpdater: identity })),
    );
  }

  #slugUpdaterChangeFlow(changeParams: LanguageChangeParams): Observable<ChangeFlow> {
    const slug = this.#urlService.getSlug(changeParams.currentUrl);
    const siteId = this.#currentSiteState.currentSite()?.id;

    if (!slug || !siteId) {
      return of({ urlUpdater: identity });
    }

    return this.#slugService.getPageUrlSlug(siteId, slug, changeParams.oldLanguage, changeParams.newLanguage).pipe(
      take(1),
      map(
        (activeSlug) =>
          ({
            urlUpdater: ([url, extras]) => [activeSlug ? url.replace(slug, activeSlug) : url, extras],
          }) as ChangeFlow,
      ),
      catchError(() => {
        this.#logger.error(
          '[LanguageChangeService][getSlugUpdaterChangeFlow] unable to retrieve new slug, request failed',
        );
        return of({ urlUpdater: identity });
      }),
    );
  }

  #combineChangeFlowsObservables(changeFlows: Observable<ChangeFlow>[]): Observable<ChangeFlow> {
    return forkJoin<ChangeFlow[]>(changeFlows).pipe(map(this.#combineUrlUpdaters.bind(this)));
  }

  #combineUrlUpdaters(changeFlows: ChangeFlow[]): ChangeFlow {
    return {
      urlUpdater: (initialParams) => changeFlows.reduce((acc, rec) => rec.urlUpdater(acc), initialParams),
    };
  }

  #getLocalizedUrlChangeFlow(changeParams: LanguageChangeParams): Observable<ChangeFlow> {
    if (this.#featureModuleChangeFlowService.canApplyChangeFlow()) {
      return this.#featureModuleChangeFlowService.getChangeFlow(changeParams);
    }

    return this.#slugUpdaterChangeFlow(changeParams);
  }
}
