import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { computed, effect, inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';

import { SERVER_RESPONSE, USER_BROWSER_LANGUAGE_KEY, WINDOW } from '@ppg/core/constants';
import { LoggerService } from '@ppg/core/logger';

import { LanguageUtilsService } from '../language-utils.service';

@Injectable({ providedIn: 'root' })
export class UserBrowserLanguageService {
  private readonly serverResponse = inject(SERVER_RESPONSE, { optional: true });
  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);
  private readonly userBrowserLanguageStateKey = makeStateKey<string | null>(USER_BROWSER_LANGUAGE_KEY);
  private readonly languageUtilsService = inject(LanguageUtilsService);
  private readonly window = inject(WINDOW);
  private readonly logger = inject(LoggerService);

  readonly language = computed(() => this.getUserBrowserLanguage());

  constructor() {
    effect(() => {
      this.transferState.set(this.userBrowserLanguageStateKey, this.language());
    });
  }

  private getUserBrowserLanguage(): string | null {
    if (isPlatformServer(this.platformId)) {
      if (this.serverResponse) {
        const acceptLanguages = this.serverResponse.req.get('Accept-Language');
        if (!acceptLanguages) {
          this.logger.info(`[getUserBrowserLanguage][Server] Accept-Language header is missing`);
          return null;
        }

        const supportedLangCodes = this.languageUtilsService.getSupportedLangCodes();
        const result = this.serverResponse.req.acceptsLanguages(supportedLangCodes) || null;
        this.logger.info(
          `[getUserBrowserLanguage][Server] Select '${result}' from preference languages: ${acceptLanguages}`,
        );
        return result;
      }
      // app started via dev server(ng serve) and it dont use server.ts and express at all
      this.logger.info(`[getUserBrowserLanguage][Server] No server response`);
      return null;
    }

    if (isPlatformBrowser(this.platformId)) {
      const transferStateLanguage = this.transferState.get(this.userBrowserLanguageStateKey, null);
      if (transferStateLanguage) {
        return transferStateLanguage;
      }
      const result = this.getUserLanguageOnClientSide();
      return result;
    }

    this.logger.error(`[getUserBrowserLanguage] Invalid platform id: ${this.platformId}`);
    return null;
  }

  private getUserLanguageOnClientSide(): string | null {
    const prefferedLanguages = this.window?.navigator?.languages;
    const supportedLangCodes = this.languageUtilsService.getSupportedLangCodes();

    for (const prefferedLanguage of prefferedLanguages) {
      const prefferedLangCode = supportedLangCodes.find((lang) => lang.startsWith(prefferedLanguage));
      if (prefferedLangCode) {
        this.logger.info(
          `[getUserBrowserLanguage][Browser] Select '${prefferedLangCode}' from preference languages: ${prefferedLanguages}`,
        );
        return prefferedLangCode;
      }
    }

    this.logger.info(`[getUserBrowserLanguage][Browser] Preference languages are not supported: ${prefferedLanguages}`);
    return null;
  }
}
