import { isPlatformBrowser, Location } from '@angular/common';
import { computed, DestroyRef, effect, inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Event, NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { distinctUntilChanged, filter, Subscription, take, tap } from 'rxjs';

import { AuthService } from '@ppg/auth';
import { UrlService } from '@ppg/configuration';
import { LoggerService } from '@ppg/core/logger';

import { InitialLanguageState } from './init/initial-language-state.service';
import { LanguageChangeService } from './language-change.service';
import { CookieLanguageService } from './language-providers/cookie-language.service';
import { UrlLanguageService } from './language-providers/url-language.service';
import { LanguageResolutionService } from './language-resolution.service';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  readonly #translocoService = inject(TranslocoService);
  readonly #urlLanguageService = inject(UrlLanguageService);
  readonly #cookieLanguageService = inject(CookieLanguageService);
  readonly #initialLanguage = inject(InitialLanguageState);
  readonly #languageResolution = inject(LanguageResolutionService);
  readonly #languageChangeService = inject(LanguageChangeService);
  readonly #router: Router = inject(Router);
  readonly #logger = inject(LoggerService);
  readonly #location = inject(Location);
  readonly #isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  readonly #destroyRef = inject(DestroyRef);
  readonly #authService = inject(AuthService);
  readonly #urlService = inject(UrlService);

  #switchLanguageSub = Subscription.EMPTY;

  readonly #language = signal('');
  readonly language = this.#language.asReadonly();
  readonly language$ = toObservable(this.#language);
  readonly countryCode = computed(() => this.language().split('-').pop());

  constructor() {
    effect(
      () => {
        /**
         * TODO: refactor #language to linkedSignal when project will be migrated to Angular 19 and linkedSignal wont be experimental
         * @link https://next.angular.dev/api/core/linkedSignal
         * @todo readonly #language = linkedSignal(() => this.initialLanguageService.language());
         */
        this.#language.set(this.#initialLanguage.language());
      },
      { allowSignalWrites: true },
    );

    effect(() => {
      this.#translocoService.setActiveLang(
        this.#translocoService.isLang(this.language()) ? this.language() : this.#translocoService.getDefaultLang(),
      );
    });

    this.#router.events
      .pipe(
        filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
        distinctUntilChanged((a, b) => a.url === b.url && a.navigationTrigger === b.navigationTrigger),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (event) => {
          this.#logger.info(
            `[LanguagesService] Starting language parsing from route event for url: ${event.url}, triggered by: ${event.navigationTrigger}`,
          );
          this.#handleUrlChange(event.url, event.navigationTrigger);
        },
      });
  }

  /**
   * Language switch flow:
   * 1. get navigation params
   * 2. if navigation params is empty, do stop and do nothing
   * 3. trigger navigation to new url
   * 4. handleUrlChange will catch route event and will update inner service state
   */
  switchLanguage(newLanguage: string): void {
    this.#logger.info(`[LanguagesService] switchLanguage to ${newLanguage}`);

    const oldLanguage = this.language();
    const currentUrl = this.#location.path(true); // path with included hash part

    if (!this.#isBrowser) {
      this.#logger.error(
        '[LanguagesService][switchLanguage] Attempt to update language on server side, skipping language switch',
      );
      return;
    }

    if (!this.#switchLanguageSub.closed) {
      this.#logger.error('[LanguagesService][switchLanguage] Language update in progress, skipping language switch');
      return;
    }

    this.#switchLanguageSub = this.#languageChangeService
      .getLanguageChangeNavigation({ currentUrl, oldLanguage, newLanguage })
      .pipe(
        take(1),
        tap((navigationParams) => {
          if (!navigationParams) {
            this.#logger.warn(
              '[LanguagesService][switchLanguage] Unable to get language switch navigation params, language switch stopped',
            );
            return;
          }

          this.#router.navigateByUrl(...navigationParams);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  #handleUrlChange(url: string, navigationTrigger: NavigationStart['navigationTrigger']): void {
    const currentLanguage = this.#language();

    const { language: newLanguage, languageSources } = this.#languageResolution.resolveLanguage(url);

    this.#logger.info(
      `[LanguagesService][handleUrlChange] url: ${url}, currentLanguage: ${currentLanguage}, resolvedLanguage: ${newLanguage}`,
    );

    if (newLanguage !== languageSources.url) {
      const targetUrl = this.#urlLanguageService.generateUrl(url, newLanguage);
      const currentNavigation = this.#urlService
        .getChunks(this.#router.url)
        .filter((chunk) => !this.#urlService.isLanguage(chunk))
        .join('/');
      const targetNavigation = this.#urlService
        .getChunks(url)
        .filter((chunk) => !this.#urlService.isLanguage(chunk))
        .join('/');

      const shouldReplacePreviousPopUrl = this.#authService.isAuthenticated() && navigationTrigger === 'popstate';
      const urlNotChanged = currentNavigation === targetNavigation;
      const replaceUrl = shouldReplacePreviousPopUrl || urlNotChanged;

      const routerExtras = this.#router.getCurrentNavigation()?.extras ?? {};
      routerExtras.replaceUrl = replaceUrl;

      const state = JSON.stringify({
        replaceUrl,
        shouldReplacePreviousPopUrl,
        urlNotChanged,
        currentNavigation,
        targetNavigation,
      });
      this.#logger.info(
        `[LanguagesService][handleUrlChange] Url contains wrong language, scheduled navigation to "${targetUrl}", state: ${state}`,
      );
      this.#router.navigateByUrl(targetUrl, routerExtras);
    }

    if (newLanguage === currentLanguage) {
      this.#logger.info('[LanguagesService][handleUrlChange] Language not changed');
      return;
    }

    this.#setLanguage(newLanguage);
  }

  #setLanguage(newLanguage: string): void {
    const oldLanguage = this.#language();
    if (!newLanguage) {
      this.#logger.warn('[LanguagesService][setLanguage] Attempt to set empty language');
      return;
    }

    if (newLanguage === oldLanguage) {
      this.#logger.warn('[LanguagesService][setLanguage] Skipping set language, not changed');
      return;
    }

    this.#logger.info(
      `[LanguagesService][setLanguage] Updating language: newLanguage: ${newLanguage}, oldLanguage: ${oldLanguage}`,
    );

    this.#cookieLanguageService.setLanguage(newLanguage);
    this.#language.set(newLanguage);
  }
}
