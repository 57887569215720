import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject } from '@angular/core';

import { LoggerService } from '../logger.service';

export class ErrorHandlerBaseService implements ErrorHandler {
  protected readonly loggerService: LoggerService = inject(LoggerService);

  constructor(private readonly serviceName: string) {}

  handleError(error: HttpErrorResponse | Error | string | unknown): void {
    const errorMessage: string = typeof error === 'string' ? error : this.getErrorMessage(error);

    this.loggerService.error(`[${this.serviceName}]: ${errorMessage}`);
  }

  private getErrorMessage(error: HttpErrorResponse | Error | unknown): string {
    if (error instanceof HttpErrorResponse) {
      return `${error.status} - ${error.statusText}`;
    } else if (error instanceof Error) {
      return `${error.name} - ${error.message}`;
    } else {
      return JSON.stringify(error);
    }
  }
}
