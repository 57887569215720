import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { OCP_APIM_SUBSCRIPTION_KEY_HEADER } from '@ppg/core/constants';
import { EnvVarsNames, ContentModelVersion } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';
import { LoggerService } from '@ppg/core/logger';

@Injectable({
  providedIn: 'root',
})
export class SlugService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly environmentService: EnvironmentService = inject(EnvironmentService);
  private readonly loggerService: LoggerService = inject(LoggerService);

  public get contentApiUrl(): string {
    const baseUrl = this.environmentService.getEnvironmentVariable(EnvVarsNames.APIM_CONTENT_API_BASE_URL);
    const versionSegment = `${ContentModelVersion.V2}/`; // TBD: remove versionSegment when ContentApi updates contracts in scope of US 986553
    return `${baseUrl}${versionSegment}`;
  }

  public get subscriptionKey(): string {
    return `${this.environmentService.getEnvironmentVariable(EnvVarsNames.APIM_CONTENT_API_SUBSCRIPTION_KEY)}`;
  }

  private get httpHeaders(): HttpHeaders {
    return new HttpHeaders().set(OCP_APIM_SUBSCRIPTION_KEY_HEADER, this.subscriptionKey);
  }

  private getHttpParams(paramsObj: { [param: string]: string | number }): HttpParams {
    return new HttpParams({ fromObject: paramsObj });
  }

  getPageUrlSlug(siteId: number, url_slug: string, lang: string, newLang: string): Observable<string> {
    return this.http
      .get(`${this.contentApiUrl}sites/${siteId}/page-url-slug`, {
        headers: this.httpHeaders,
        params: this.getHttpParams({
          ...(url_slug && { url_slug }),
          ...(lang && { lang }),
          ...(newLang && { newLang }),
        }),
        responseType: 'text',
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(
            `[SlugService][getPageUrlSlug] Error occurred while fetching pageUrlSlug: ${e.message}.`,
          );
          return throwError(() => e);
        }),
      );
  }
}
