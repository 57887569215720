import { inject, Injectable } from '@angular/core';

import { ConfigManagerService } from '../config-manager/config-manager.service';
import { UrlService } from '../url/url.service';

@Injectable({ providedIn: 'root' })
export class PathService {
  readonly #urlService = inject(UrlService);
  readonly #configManagerService = inject(ConfigManagerService);

  public getPageHierarchy(path: string): string | null {
    const pageAndHierarchyChunks = this.#getPageAndHierarchyChunks(path);

    const hierarchyChunks = pageAndHierarchyChunks.slice(0, -1);

    if (!hierarchyChunks.length) {
      return null;
    }

    return hierarchyChunks.join('/');
  }

  public getPageUrlAndHierarchy(path: string): string | null {
    const pageAndHierarchyChunks = this.#getPageAndHierarchyChunks(path);

    if (!pageAndHierarchyChunks.length) {
      return null;
    }

    return pageAndHierarchyChunks.join('/');
  }

  #getPageAndHierarchyChunks(path: string) {
    let chunks = this.#urlService.getChunks(path);
    const rootNameIndex = chunks.findIndex((chunk) => this.#configManagerService.isActiveSiteRootName(chunk));

    if (rootNameIndex === -1) {
      chunks = this.#ensureChunksHaveNoLanguage(chunks);
    }

    return chunks.slice(rootNameIndex + 1);
  }

  #ensureChunksHaveNoLanguage(chunks: string[]) {
    if (chunks.length && this.#urlService.isLanguage(chunks[0])) {
      return chunks.slice(1);
    }

    return chunks;
  }
}
