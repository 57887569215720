/* eslint-disable no-useless-escape */
export const Patterns = {
  PhoneNumberMatchRegex:
    /^(?:\+[\d]{1,3}\s?)?(?:\([\d]{2,6}\)|[\d]{2,6})(?:\s(?![\.-\s]))?(?:(?:\s?[\d]+){1,4}|(?:\.?[\d]+){1,4}|(?:-?[\d]+){1,4})$/, // https://regex101.com/r/g6rzvb/6
  EmailRegex:
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
  NotOnlyWhitespacesRegex: '^.*\\S.*$',
  OnlyNumbers: '^[0-9]+$',
  State: '^[A-Z]{2}-[A-Z]{2}$',
  OnlyLettersDigitsAndHyphen: '^[a-zA-Z0-9]+([ -‒–—―−][a-zA-Z0-9]+)*$',
  OnlyLettersAndDigits: /[^a-zA-Z0-9]/g,
  isPPGEmailRegex: /^.+@ppg.com$/i,
  AccountNumber: /^\d{12}$/,
  AccountNumber16digits: /^\d{12}$|^\d{16}$/,
  PaymentCardHolder: /^(?![-().@&'!])[^#$*_\[\]^`{}|<>~=]*$/,
  NoLeadingAndTrailingWhitespaces: /^\S(.*\S)?$/,
  Postcodes: {
    US: {
      RegexToMatch: '^[0-9]{5}(-[0-9]{4})?$',
    },
    CA: {
      RegexToMatch: '^[A-Za-z]\\d[A-Za-z]\\s\\d[A-Za-z]\\d$',
    },
    GB: {
      RegexToMatch: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
    },
    SE: {
      RegexToMatch: '^[0-9]{5}$',
      Mask: '99999',
    },
    NL: {
      RegexToMatch: '^[0-9]{4}\\s?[A-Za-z]{2}$',
    },
  },
};
