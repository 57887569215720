import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UrlCreatorService {
  public createPageUrl(
    lang?: string | null,
    rootName?: string | null,
    pageHierarchy?: string | null,
    urlSlug?: string | null,
  ): string {
    const hierarchyChunks = pageHierarchy?.split('/').filter(Boolean) ?? [];

    const urlChunks = [lang, rootName, ...hierarchyChunks, urlSlug]
      .filter(Boolean)
      .map((chunk) => this.#trimSlashes(chunk!));

    return `/${urlChunks.join('/')}`;
  }

  #trimSlashes(chunk: string): string {
    if (chunk.charAt(0) == '/') {
      chunk = chunk.substring(1);
    }

    if (chunk.charAt(chunk.length - 1) == '/') {
      chunk = chunk.substring(0, chunk.length - 1);
    }

    return chunk;
  }
}
