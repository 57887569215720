import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UrlTarget } from '@ppg/core/enums';
import { CTA } from '@ppg/core/models';

@Injectable({
  providedIn: 'root',
})
export class UrlCtaService {
  readonly #router: Router = inject(Router);
  readonly #document: Document = inject(DOCUMENT);

  isAbsoluteUrlPath(url?: string): boolean {
    return url ? url.startsWith('http://') || url.startsWith('https://') || url.includes('www.') : false;
  }

  isRelativePathWithSelfTarget(url?: string, target?: UrlTarget): boolean {
    if (!url || !target) return false;

    return target === UrlTarget.SELF && !this.isAbsoluteUrlPath(url);
  }

  parseUrl(url?: string): { path?: string; queryParams?: Record<string, string> | null; anchor?: string } {
    if (!url) return {};

    const [fullPath, anchor] = url.split('#');
    const [path, queryString] = fullPath.split('?');

    const queryParams: Record<string, string> = {};
    if (queryString) {
      queryString.split('&').forEach((param) => {
        const [key, value] = param.split('=');
        if (key) {
          queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
        }
      });
    }

    return {
      path,
      queryParams: Object.keys(queryParams).length > 0 ? queryParams : null,
      anchor,
    };
  }

  navigate(cta: CTA | undefined): void {
    const url = cta?.content?.url ?? undefined;
    const target = cta?.content?.target ?? undefined;

    if (this.isRelativePathWithSelfTarget(url, target)) {
      const { path, anchor: fragment, queryParams } = this.parseUrl(url);
      this.#router.navigate([path], { fragment, queryParams });
    } else {
      this.#document.defaultView?.open(url, target);
    }
  }

  isTargetBlank(cta?: CTA): boolean {
    return cta?.content?.target === UrlTarget.BLANK;
  }
}
