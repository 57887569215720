import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppRoutesResponse, ContentService } from '@ppg/core/content';

@Injectable({ providedIn: 'root' })
export class AppRoutesApiService {
  readonly #contentService = inject(ContentService);

  getAppRoutes(siteId: number, lang?: string): Observable<AppRoutesResponse> {
    return this.#contentService.getAppRoutes(siteId, lang);
  }
}
