import { InjectionToken } from '@angular/core';
import { TelemetryClient } from 'applicationinsights';
import type { Response, Request } from 'express';

import { Site, StaticAssets } from '@ppg/core/models';

export const CONFIG = new InjectionToken<Site[]>('CONFIG');
export const LOGGER = new InjectionToken<TelemetryClient>('LOGGER');
export const SERVER_RESPONSE = new InjectionToken<Response>('SERVER_RESPONSE');
export const SERVER_REQUEST = new InjectionToken<Request>('SERVER_REQUEST');
export const WINDOW = new InjectionToken<Window>('Window');
export const IS_AUTHENTICATED = new InjectionToken<boolean>('IS_AUTHENTICATED');
export const GLOBAL_STATIC_ASSETS_TOKEN = new InjectionToken<StaticAssets>('GLOBAL_STATIC_ASSETS');
