import type { BaseLogger } from 'pino';

export class NoopLogger implements BaseLogger {
  level = 'debug';
  readonly fatal = () => {};
  readonly error = () => {};
  readonly warn = () => {};
  readonly info = () => {};
  readonly debug = () => {};
  readonly trace = () => {};
  readonly silent = () => {};
}
