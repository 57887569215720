import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { AuthApiService } from '../data/services/auth-api/auth-api.service';
import { UserApiService } from '../data/services/user/user-api.service';
import { UserService } from '../data/services/user/user.service';
import { AuthService } from '../domain/repository/auth-service.abstract';
import { AuthUseCases } from '../domain/use-cases/auth-use-cases';

export function provideDefaultAuth(): EnvironmentProviders {
  return makeEnvironmentProviders([
    AuthApiService,
    {
      provide: AuthService,
      useFactory: () => new AuthUseCases(),
    },
    UserApiService,
    UserService,
  ]);
}
