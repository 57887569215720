export const CAROUSEL_CONTENT_BANNER_BREAKPOINTS = [
  {
    breakpoint: '1440px',
    numVisible: 3,
    numScroll: 3,
  },
  {
    breakpoint: '1079px',
    numVisible: 2,
    numScroll: 2,
  },
  {
    breakpoint: '560px',
    numVisible: 1,
    numScroll: 1,
  },
];
