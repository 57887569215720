import { inject, Injectable } from '@angular/core';

import { CurrentSiteState, UrlService } from '@ppg/configuration';
import { ClientSiteSettingsKeys, Locale } from '@ppg/core/enums';

@Injectable({ providedIn: 'root' })
export class LanguageUtilsService {
  private readonly urlService = inject(UrlService);
  private readonly currentSiteState = inject(CurrentSiteState);

  isLanguageSupported(lang: string | null): boolean {
    if (!lang) {
      return false;
    }

    const formattedLanguage = this.urlService.formatLanguageToCode(lang);
    return !!formattedLanguage && this.getSupportedLangCodes().includes(formattedLanguage);
  }

  getSupportedLangCodes(): string[] {
    const siteLanguages = this.currentSiteState.siteLanguages();
    const siteLanguagesCodes = siteLanguages.map((lang) => lang.languageCulture);

    if (this.currentSiteState.isPreviewMode()) {
      const globalLangSetting = this.currentSiteState
        .currentSite()
        ?.siteSettings?.find((set) => set.key == ClientSiteSettingsKeys.KenticoLocalizationsGlobalLanguages)?.value;
      if (globalLangSetting) {
        const globalLanguages = globalLangSetting.split(',').map((lang) => lang.trim());
        siteLanguagesCodes.push(...globalLanguages);
      }
    }

    return siteLanguagesCodes.length ? siteLanguagesCodes : [Locale.EN_US];
  }
}
