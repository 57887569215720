import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { API_BASE_URL } from '@ppg/core/constants';
import { LoggerService } from '@ppg/core/logger';
import { SessionConfigs } from '@ppg/core/models';

import { CurrentSiteState } from '../../states/current-site.state';

@Injectable({ providedIn: 'root' })
export class SessionConfigurationService {
  #http: HttpClient = inject(HttpClient);
  #baseUrl = inject(API_BASE_URL);
  #logger: LoggerService = inject(LoggerService);
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #currentConfiguration = signal<SessionConfigs>({} as SessionConfigs);
  readonly currentConfiguration = this.#currentConfiguration.asReadonly();

  async getSessionConfiguration() {
    this.#logger.info('[getConf] getSessionConfiguration called');
    await firstValueFrom(
      this.#http.get<SessionConfigs>(`${this.#baseUrl}session/${this.#currentSiteState.siteId()!}/user-session`, {
        transferCache: false,
      }),
    )
      .then((config) => {
        this.#currentConfiguration.set(config);
      })
      .catch((error) => {
        this.#logger.error(`[getConf] getSessionConfiguration failed ${error}`);
      });
  }
}
