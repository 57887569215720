import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, Routes } from '@angular/router';
import { filter, map, take } from 'rxjs';

import { LoggerService } from '@ppg/core/logger';

import { AppRoutesService } from '../services/app-routes.service';

let lastAppliedConfig: Routes = [];

export const dynamicRouterUpdaterGuard = (() => {
  const appRoutesService = inject(AppRoutesService);
  const router = inject(Router);
  const logger = inject(LoggerService);

  return appRoutesService.appRouteItemsLoading$.pipe(
    filter((loading) => !loading),
    take(1),
    map(() => {
      const currentRouterConfig = appRoutesService.routerConfig();
      const configChanged = lastAppliedConfig !== currentRouterConfig;

      if (configChanged) {
        lastAppliedConfig = currentRouterConfig;
        router.resetConfig(currentRouterConfig);
        logger.info(`[dynamicRouterUpdaterGuard] new router config is applied`);
        // Pass processing to canActivate guard with redirect to the same url
        return true;
      }

      return false;
    }),
  );
}) satisfies CanMatchFn;

export const dynamicRouterUpdaterRedirectGuard = ((_, state) => {
  const router = inject(Router);
  const logger = inject(LoggerService);
  logger.info(`[dynamicRouterUpdaterRedirectGuard] reset navigation for ${state.url}`);
  return router.parseUrl(state.url);
}) satisfies CanActivateFn;
