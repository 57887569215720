export enum AppStaticRoutes {
  NOT_FOUND = 'notfound',
  SERVER_ERROR = 'error',
  PREVIEW = 'preview',
}

export enum AuthStaticRoutes {
  LOGIN = 'login',
  SIGNOUT = 'signout',
}

export enum DistributorLocatorStaticRoutes {
  ITEM = 'item',
}
