import { InjectionToken } from '@angular/core';

export class CacheMemoryService {
  #cache = new Map<string, unknown>();

  constructor(invalidatePeriod: number) {
    setInterval(() => this.#cache.clear(), invalidatePeriod);
  }

  hasValue(key: string): boolean {
    return this.#cache.has(key);
  }

  getValue<T>(key: string): T | null {
    return (this.#cache.get(key) ?? null) as T;
  }

  setValue(key: string, value: unknown): void {
    this.#cache.set(key, value);
  }
}

export const APP_CACHE_PROVIDER = new InjectionToken<CacheMemoryService>('APP_CACHE_PROVIDER');
