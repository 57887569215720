import { computed, inject, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, defer, map, Observable, of, startWith, switchMap } from 'rxjs';

import { ConfigManagerService, CurrentSiteState } from '@ppg/configuration';
import { AppRouteItem, AppRoutesResponse, FeatureModule } from '@ppg/core/content';

import { AppRoutesApiService } from '../services/app-routes-api.service';
import { RouterConfigBuilderService } from '../services/router-config-builder.service';
import { getPathByAppRouteFeatureModule } from '../utils/app-route-utils';

const DEFAULT_APP_ROUTES: AppRoutesResponse = [];

@Injectable({ providedIn: 'root' })
export class AppRoutesService {
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #configManagerService = inject(ConfigManagerService);
  readonly #appRoutesApiService = inject(AppRoutesApiService);
  readonly #routerConfigBuilderService = inject(RouterConfigBuilderService);

  readonly #getAppRoutesRequest = computed(() =>
    this.#getAppRoutes(this.#routerConfigBuilderService.language(), this.#currentSiteState.siteId()),
  );
  readonly #getRoutesResponse$: Observable<{ loading: boolean; data: AppRoutesResponse }> = toObservable(
    this.#getAppRoutesRequest,
  ).pipe(
    switchMap((request) =>
      request.pipe(
        map((response) => ({ loading: false, data: response })),
        startWith({ loading: true, data: DEFAULT_APP_ROUTES }),
      ),
    ),
  );

  readonly #getRoutesResponse = toSignal(this.#getRoutesResponse$, {
    initialValue: { loading: false, data: DEFAULT_APP_ROUTES },
  });

  readonly appRouteItemsLoading = computed(() => this.#getRoutesResponse().loading);
  readonly appRouteItemsLoading$ = toObservable(this.appRouteItemsLoading);
  readonly appRouteItems = computed(() => this.#getRoutesResponse().data ?? []);
  readonly routerConfig = computed(() => this.#routerConfigBuilderService.getRouterConfig(this.appRouteItems()));

  rootAppRouteItems$ = defer(() =>
    this.#getAppRoutes(this.#routerConfigBuilderService.language(), this.#configManagerService.rootSite()?.id),
  ).pipe(map((response) => response ?? []));

  getAppRoutes(language: string): Observable<AppRouteItem[]> {
    return this.#getAppRoutes(language, this.#currentSiteState.siteId());
  }

  getPathByAppRouteFeatureModule(featureModule: FeatureModule): string | null {
    return getPathByAppRouteFeatureModule(this.appRouteItems(), featureModule);
  }

  getRootPathByAppRouteFeatureModule$(featureModule: FeatureModule): Observable<string | null> {
    return this.rootAppRouteItems$.pipe(map((routeItems) => getPathByAppRouteFeatureModule(routeItems, featureModule)));
  }

  #getAppRoutes(language: string, siteId: number | undefined): Observable<AppRoutesResponse> {
    if (!siteId) {
      return of(DEFAULT_APP_ROUTES);
    }

    return this.#appRoutesApiService.getAppRoutes(siteId, language).pipe(catchError(() => of(DEFAULT_APP_ROUTES)));
  }
}
