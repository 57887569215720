import { computed, inject, Injectable } from '@angular/core';

import { ConfigManagerService, UrlService } from '@ppg/configuration';
import { CONTENT_PREVIEW_MODE, SITE_WITH_ROOT_NAME_LANGUAGE_SEGMENT_INDEX } from '@ppg/core/constants';

import { LanguageUtilsService } from '../language-utils.service';

@Injectable({ providedIn: 'root' })
export class UrlLanguageService {
  readonly #languageUrlIndex = 0;
  readonly #previewLanguageUrlIndex = 2;

  readonly #configManagerService = inject(ConfigManagerService);
  readonly #languageUtilsService = inject(LanguageUtilsService);
  readonly #urlService = inject(UrlService);

  readonly #isUrlCollection = computed(() => this.#configManagerService.isCurrentSiteCollection());

  generateUrl(url: string, language: string): string {
    const urlObj = new URL(url, 'https://example.com');
    const splittedUrl = this.#urlService.getChunks(urlObj.pathname);
    const filteredUrl = splittedUrl.filter((urlEl) => !this.#languageUtilsService.isLanguageSupported(urlEl));

    const languageIndex = this.#getLanguageUrlIndex(url);

    if (this.#urlService.isLanguage(filteredUrl[languageIndex])) {
      // url contains language, replacing
      filteredUrl.splice(languageIndex, 1, language);
    } else {
      filteredUrl.splice(languageIndex, 0, language);
    }

    return `/${filteredUrl.join('/')}${urlObj.search}${urlObj.hash}`;
  }

  getRawLanguageFromUrl(url: string): string | null {
    const urlSplit = this.#urlService.getChunks(url);
    const languageIndex = this.#getLanguageUrlIndex(url);

    const languagePart = urlSplit[languageIndex];

    return this.#languageUtilsService.isLanguageSupported(languagePart) ? languagePart : null;
  }

  #getLanguageUrlIndex(url: string): number {
    if (this.#isPreviewMode(url)) {
      return this.#previewLanguageUrlIndex;
    }

    return this.#isUrlCollection() ? SITE_WITH_ROOT_NAME_LANGUAGE_SEGMENT_INDEX : this.#languageUrlIndex;
  }

  #isPreviewMode(url: string): boolean {
    const chunks = this.#urlService.getChunks(url);
    return chunks.findIndex((chunk) => chunk === CONTENT_PREVIEW_MODE) !== -1;
  }
}
